import { NotificationAdapter } from "./NotificationAdapter";
import toastr from "toastr";
import toastrOptions from "./toastrOptions";

export class ToastrAdapter extends NotificationAdapter {
	error(message, title = "") {
		return toastr.error(message, title, toastrOptions.Error);
	}
	success(message, title = "") {
		return toastr.success(message, title, toastrOptions.Success);
	}
	info(message, title = "") {
		return toastr.info(message, title, toastrOptions.Info);
	}
	warning(message, title = "") {
		return toastr.warning(message, title, toastrOptions.Warning);
	}
}

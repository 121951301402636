export const APP_ENTRY = {
    content: "content"
};

export const STICKY_HEADER = {
    stuckHeader: "grid-x header sticky",
    menuCell: "cell small-order-2 medium-order-3 large-order-3 small-6 medium-3 large-3 menu",
    logoCell: "cell small-order-1 medium-order-1 large-order-1 small-6 medium-3 large-3 logo",
    titleCell: "cell small-order-3 medium-order-2 large-order-2 small-12 medium-auto large-auto title",
    navigationIcon: "icon-navicon",
    menuContainer: "menu-container",
    menuItems: "menu-items"
};

export const TEXTFIELD = {
    wrapperClass: "grid-x textfield-wrapper form-group form-inline",
    textField: "textfield",
    input: "cell small-12 medium-6 large-6",
    label: "cell small-12 medium-3 large-3 textfieldLabel",
};

export const DROPDOWNFIELD = {
    wrapperClass: "grid-x dropdownfield-wrapper form-group form-inline",
    dropdownField: "dropdownfield",
    input: "cell small-12 medium-6 large-6",
    label: "cell small-12 medium-3 large-3 dropdownfieldLabel",
};

export const TEXTAREA = {
    wrapperClass: "grid-x",
    textArea: "textarea",
    input: "cell small-12 medium-12 large-12 textarea-wrapper",
    label: "cell small-12 medium-12 large-12",
};

export const CHECKBOXFIELD = {
    wrapperClass: "grid-x form-group form-inline",
    checkboxField: "checkboxfield",
    label: "cell medium-offset-3 large-offset-3 small-12 medium-9 large-9 checkboxfieldLabel",
};

export const SAVEBUTTON = {
    wrapperWrapperClass: "clearfix",
    wrapperClass: "grid-x right",
    button: "button save-button"
}

export const BANNER = {
    //bannerContainer: "grid-x banner-container",
    bannerContainer: "grid-x content-block content-header",
    bannerContent: "-header",
    bannerImage: "cell",
    bannerText: "banner-text cell"

};

export const REFLECTIONS = {
    reflection: "reflection",
    reflectionText: "reflectionText",
    reflectionResp: "reflectionResp",
}

export const ASSESSMENT = {
    assessment: "",
    assessmentQuestion: "content-block content-questionnaire",
    assessmentButtonWrapper: "grid-x response-container",
    assessmentButton: "cell response-cell small-12 large-3 medium-3",
}

export const FOOTER = {
  footerContainer: "grid-x footer-container",
  footerContent: "cell align-center"
};
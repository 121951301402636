import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Banner from "../../Components/Common/Banner";
import locations from "../../Helpers/locations";
import {setPageTitle} from "../../Helpers/setPageTitle";
import {ReportType} from "./selfAssessmentConstants";
import SelfAssessmentGuidance from "../../Data/SelfAssessmentGuidance.pdf";

const Overview = () => {
    useLayoutEffect(() => {
        setPageTitle("Self Assessment Overview");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; <strong>Self Assessment</strong>
                </div>

                <div className="content-block content-description">
                    <div className={"grid-x grid-margin-x"}>
                        <div className={"cell medium-7 small-12"}>
                            <p>The purpose of this self assessment is to enable school teams to reflect deeply on their
                                strengths
                                and areas of need regarding the systems, mindsets, and practices of their data capacity.
                                The
                                data
                                capacity self-assessment is also intended to guide the team to reflect on how data is
                                used from
                                an
                                equity perspective.
                            </p>
                            <p>
                                Although the self-assessment will result in ratings against a rubric, the purpose of the
                                rubric
                                is
                                to drive dialogue and inform strategic improvements within your school. The exact
                                ratings are
                                not
                                as important as how they were determined. The assessment can be taken in
                                full by selecting Complete Full Self-Assessment or you
                                may complete specific sections of the assessment by
                                selecting the specific assessment section. For guidance
                                on completing the self assessment please refer to the
                                &quot;<a href={SelfAssessmentGuidance} target="_blank" rel="noreferrer noopener" download>Self Assessment Guidance</a>&quot;.
                            </p>
                        </div>
                        <div className={"cell medium-5 small-12 align-center"}>
                            <Link to={locations.selfAssessment.selfAssessment.getPath(ReportType.Full.path)}
                                  className="button light-action-button wide-action-button ">Complete {ReportType.Full.title}<br/>Self-Assessment</Link>

                            <p><strong>By Section</strong></p>
                            <Link
                                to={locations.selfAssessment.selfAssessment.getPath(ReportType.SystemsAndResources.path)}
                                className="button light-action-button wide-action-button ">{ReportType.SystemsAndResources.title} Assessment</Link>
                            <Link
                                to={locations.selfAssessment.selfAssessment.getPath(ReportType.BehaviorsAndMindset.path)}
                                className="button light-action-button wide-action-button ">{ReportType.BehaviorsAndMindset.title} Assessment</Link>
                            <Link
                                to={locations.selfAssessment.selfAssessment.getPath(ReportType.SkillsAndKnowledge.path)}
                                className="button light-action-button wide-action-button ">{ReportType.SkillsAndKnowledge.title} Assessment</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Overview;

import React, {useLayoutEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Banner from "../../Components/Common/Banner";
import RoleGrid from "../../Components/SelfAssessmentReport/RoleGrid";
import locations from "../../Helpers/locations";
import {setPageTitle} from "../../Helpers/setPageTitle";
import {participantManager, reportingManager} from "../../Components/SelfAssessment/AssessmentLogic";
import ReportSection from "../../Components/SelfAssessmentReport/ReportSection";
import {ReportType, ReportTypeList} from "./selfAssessmentConstants";
import Resources from "../../Components/SelfAssessmentReport/Resources";

function SelfAssessmentReport() {
    const [sections, setSections] = useState([]);

    const [reportType, setReportType] = useState(null);
    const [participantId, setParticipantId] = useState(null);
    const {...params} = useParams();

    const isFullReport = () => {
        return ReportType.Full.title === reportType.title;
    };

    useLayoutEffect(() => {
        setPageTitle("Self Assessment Section Report");

        const report = ReportType[params.reportType] ? ReportType[params.reportType] : ReportType.Full;
        setReportType(report);

        const pId = !params.participantId ? null : params.participantId;
        setParticipantId(pId);
    }, []);


    useLayoutEffect(() => {
        if (!reportType) {
            return;
        }

        const filterSectionsByReportType = () => {
            let sections = ReportTypeList.filter(f => (!isFullReport() && f.path === reportType.path) || (isFullReport() && f.path !== ReportType.Full.path));
            setSections(sections);
        };

        filterSectionsByReportType();

    }, [reportType]);

    if (!reportType)
        return null;

    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Self Assessment &gt; <strong>Report</strong>
                </div>
                {
                    sections.map((section, index) => {
                        return (
                            <ReportSection key={`reportSection_${index}`} category={section.title}
                                           path={reportType.path} participantId={participantId}/>
                        );
                    })
                }
                {
                    !isFullReport() &&
                    !participantId &&
                    <>
                        <RoleGrid sections={sections} path={reportType.path}/>
                        <Resources
                            categoryAveragesForChart={reportingManager.getCategoryAverages(reportType.path, sections)}/>
                    </>
                }
            </div>
            <div className="content-block content-navigation-buttons">
                {
                    isFullReport() &&
                        !participantId &&
                    <Link
                        to={locations.selfAssessment.reportChart.getPath(reportType.path)}
                        className="button next-button">NEXT &#9654;</Link>
                }
                {
                    (!isFullReport() || participantId !== null) &&
                    <Link to={locations.selfAssessment.selfAssessment.getPath(reportType.path)}
                          className="button next-button">NEXT &#9654;</Link>
                }
                <Link to={locations.selfAssessment.selfAssessment.getPath(reportType.path)}
                      className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default SelfAssessmentReport;

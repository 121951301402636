import { matchPath } from "react-router-dom";

export const params = {
    reportType: ":reportType",
    participantId: ":participantId",
};

export default {
    home: "/",
    intro: {
        page1: "/intro/01",
        page2: "/intro/02"
    },
    part01: {
        index: "/part01",
        sectionA: {
            page1: "/part01/sectionA/01",
            page2: "/part01/sectionA/02",
            page3: "/part01/sectionA/03"
        },
        sectionB: {
            page1: "/part01/sectionB/01",
            page2: "/part01/sectionB/02",
            page3: "/part01/sectionB/03"
        },
        sectionC: {
            page1: "/part01/sectionC/01",
            page2: "/part01/sectionC/02"
        }
    },
    part02: {
        index: "/part02",
        sectionA: {
            page1: "/part02/sectionA/01"
        },
        sectionB: {
            page1: "/part02/sectionB/01",
            page2: "/part02/sectionB/02"
        },
        sectionC: {
            page1: "/part02/sectionC/01"
        },
        sectionD: {
            page1: "/part02/sectionD/01"
        }
    },
    finalReport: "/FinalReport",
    selfAssessment: {
        overview: "/selfAssessment/overview",
        selfAssessment: location((reportType = params.reportType) => `/selfAssessment/selfAssessment/${reportType}`),
        individualReport: location((reportType = params.reportType, participantId = params.participantId) => `/selfAssessment/report/${reportType}/${participantId}`),
        report: location((reportType = params.reportType) => `/selfAssessment/report/${reportType}`),
        reportChart: location((reportType = params.reportType) => `/selfAssessment/reportChart/${reportType}`),
        page1: location((reportType = params.reportType) => `/selfAssessment/01/${reportType}`),
        page2: "/selfAssessment/02",
        page3: "/selfAssessment/03"
    }
};

const exception = () => {
    throw new Error("Method getUrl not implemented.");
};

export function location(getPath = exception) {
    let result = { getPath };
    result.path = getPath();
    result.getParams = (currentPath) => getParams(currentPath, result.path);

    return result;
}

function getParams(currentPath, routeToMatch) {
    const matchedInfo = matchPath(currentPath, {
        path: routeToMatch,
        exact: false,
        strict: false,
    });

    return matchedInfo ? matchedInfo.params : null;
}

import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import imgFldoe from '../../../Images/fldoe-logo.png'
import imgCims from '../../../Images/cims-logo.png'
import imgEdstats from '../../../Images/edstats-logo.png'
import imgEdudata from '../../../Images/edudata-logo.png'
import Banner from "../../../Components/Common/Banner"
import locations from "../../../Helpers/locations";
import {setPageTitle} from "../../../Helpers/setPageTitle";

function Intro01() {
    useLayoutEffect(() => {
        setPageTitle("Data Navigation Tool");
    }, []);

    return (

        <>
            <Banner page="dnt"/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; <strong>Data Navigation Tool</strong>
                </div>

                <div className="content-block content-description">
                    <div className="content-image-container">
                        <div className="content-image">
                            <a href="http://www.fldoe.org" target="_blank" rel="noopener noreferrer"><img src={imgFldoe} alt='FLDOE'/></a>
                        </div>
                        <div className="content-image">
                            <a href="https://www.floridacims.org" target="_blank" rel="noopener noreferrer"><img
                                src={imgCims} alt='CIMS'/></a>
                        </div>
                        <div className="content-image">
                            <a href="https://edstats.fldoe.org" target="_blank" rel="noopener noreferrer"><img
                                src={imgEdstats} alt='EDStats Portal'/></a>
                        </div>
                        <div className="content-image">
                            <a href="https://edudata.fldoe.org" target="_blank" rel="noopener noreferrer"><img
                                src={imgEdudata} alt='EduData'/></a>
                        </div>
                    </div>
                    <br/>
                    <h2>The Data Navigation Guide is designed to help you:</h2>
                    <ul>
                        <li>Leverage state data, local data, and school-level student data to ensure equitable student
                            outcomes.
                        </li>
                        <li>Understand how these data can help inform instructional decisions.</li>
                        <li>Become more familiar with available data sources, including state data, local data, and
                            school-level data for equity analysis.
                        </li>
                    </ul>
                </div>
                <div className="content-block content-questionnaire">
                    <div className="question-container gold">
                        <p className="question-text">
                            <span className="critical-question">Critical Question</span><br/>
                            Do our instructional practices ensure that all students in our school receive high-quality
                            opportunities to learn?
                        </p>
                        <Link to={locations.part01.index} className="button wide-action-button question-button">Explore your state
                            data for this question</Link>
                    </div>
                    <div className="question-container lightgold">
                        <p className="question-text">
                            What other local data might help inform our perspective on this question?
                        </p>
                        <Link to={locations.part02.index} className="button wide-action-button question-button">Find out more about your
                            local data sources</Link>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Intro01;

import React, {useLayoutEffect, useState} from "react";
import {animateScroll as scroll} from "react-scroll";
import {NotificationFactory} from "../../../Components/Notification/NotificationFactory";
import SelfAssessmentDirections from "../../../Components/SelfAssessment/SelfAssessmentDirections";
import SelfAssessmentSection from "../../../Components/SelfAssessment/SelfAssessmentSection";
import Demographics from "../../../Components/SelfAssessment/Demographics";
import {Link, useHistory, useParams} from "react-router-dom";
import {FormValidation} from "calidation";
import {participantManager, assessmentStorage} from "../../../Components/SelfAssessment/AssessmentLogic";
import locations from "../../../Helpers/locations";
import {setPageTitle} from "../../../Helpers/setPageTitle";
import {createGuid} from "../../../Helpers/utilities";
import {ReportType, assessmentIndicators} from "../selfAssessmentConstants";

const notificationFactory = new NotificationFactory();

function AssessmentPage01() {
    const history = useHistory();
    const {...params} = useParams();
    const [reportType, setReportType] = useState(null);

    const formConfig = {
        isAnonymous: {},
        fName: {
            isRequired: {
                message: 'First name is required!',
                validateIf: ({fields}) => !fields.isAnonymous,
            }
        },
        lName: {
            isRequired: {
                message: 'Last name is required!',
                validateIf: ({fields}) => !fields.isAnonymous,
            }
        },
        email: {
            isRequired: {
                message: 'Email is required!',
                validateIf: ({fields}) => !fields.isAnonymous,
            }
        },
        school: {},
        district: {},
        role: {
            isRequired: "Role is required"
        }
    };

    const isFullReport = () => {
        return ReportType.Full.title === reportType.title;
    };

    const categoryTitle = () => {
        return isFullReport() ? ReportType.SystemsAndResources.title : reportType.title;
    };

    const categoryPath = () => {
        return isFullReport() ? ReportType.SystemsAndResources.path : reportType.path;
    };

    const onSubmit = ({fields, isValid}) => {
        if (!isValid) {
            notificationFactory.createError("Demographic information is missing some data.").notify();
            scroll.scrollToTop({
                duration: 150,
            });
            return;
        }

        const questionIds = assessmentIndicators.filter(f => f.Category === categoryTitle()).map((indicator) => indicator.id);

        if (!assessmentStorage.validateAllResponsesSelected(reportType.path, questionIds, participantManager.getCurParticipantId())) {
            notificationFactory.createError("Some ratings have not been selected. Please make sure to select a response to each one.").notify();
            scroll.scrollToTop({
                duration: 150,
            });
            return;
        }

        const demoInfo = {...fields};

        if (demoInfo.isAnonymous && participantManager.getCurParticipantId() === participantManager.NO_DATA) {
            demoInfo.email = createGuid();
            demoInfo.fName = "Anonymous";
            demoInfo.lName = "User";
            demoInfo.school = "";
            demoInfo.district = "";
        }

        let participantId = demoInfo.email;
        if (participantId.length > 0) {
            participantManager.setParticipantData(reportType.path, participantId, demoInfo);
            participantManager.addParticipantId(reportType.path, participantId);
            participantManager.setCurParticipantId(participantId);
        }

        assessmentStorage.switchParticipantTemporaryResponses(reportType.path, [...questionIds, assessmentStorage.getReflectionKey(categoryPath())], participantId);

        if (isFullReport())
            history.push(locations.selfAssessment.page2);
        else {
            participantManager.resetCurParticipantId();
            history.push(locations.selfAssessment.selfAssessment.getPath(reportType.path));
        }
    };

    const setInitialValues = () => {
        let initValues = {
            isAnonymous: false,
            fName: "",
            lName: "",
            email: "",
            school: "",
            district: "",
            role: ""
        };

        if (participantManager.getCurParticipantId() !== participantManager.NO_DATA) {
            let pData = participantManager.getCurParticipantData(reportType.path);
            if (pData !== participantManager.NO_DATA) {
                initValues = pData;
            }
        }

        return initValues;
    };

    useLayoutEffect(() => {
        const getReportTypeParam = () => {
            const report = ReportType[params.reportType] ? ReportType[params.reportType] : ReportType.Full;
            setReportType(report);
            setPageTitle(`${report.title} Self-Assessment`);
        };

        getReportTypeParam();
    }, []);

    if(!reportType)
        return null;

    return (
        <FormValidation onSubmit={onSubmit} config={formConfig} initialValues={setInitialValues()}>
            {({errors, fields, submitted}) => (
                <>
                    <div className="grid-x content-container">
                        <div className="content-block content-breadcrumbs">
                            Overview &gt; Self Assessment &gt; <strong>Participant</strong>
                        </div>
                        <div className="content-block content-description text-center">
                            <h2>School Demographic Information</h2>
                        </div>

                        <div className="content-block content-questionnaire">
                            <Demographics config={fields} errors={errors} isSubmitted={submitted}/>
                        </div>

                        <SelfAssessmentDirections/>

                        <div className="content-block content-description text-center">
                            <h2>{categoryTitle()}</h2>
                        </div>

                        <SelfAssessmentSection sectionTitle={categoryTitle()} category={categoryPath()}
                                               path={reportType.path}/>

                    </div>
                    <div className="content-block content-navigation-buttons">
                        {
                            isFullReport() &&
                            <input type="submit" className="button next-button" value="NEXT &#9654;"/>
                        }
                        {
                            !isFullReport() &&
                            <input type="submit" className="button next-button" value={"SUBMIT"}/>
                        }
                        <Link to={locations.selfAssessment.selfAssessment.getPath(reportType.path)}
                              className="button back-button">&#9664; BACK</Link>
                    </div>
                </>
            )}
        </FormValidation>
    );
}

export default AssessmentPage01;

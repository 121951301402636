import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Banner from "../../../Components/Common/Banner"
import locations from "../../../Helpers/locations";
import {setPageTitle} from "../../../Helpers/setPageTitle";

function Part01() {
    useLayoutEffect(() => {
        setPageTitle("Instruction and Learning");
    }, []);
    return (
        <>
            <Banner page="dnt"/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; <strong>Part I: Instruction and
                    Learning</strong>
                </div>

                <div className="content-block content-description text-center">
                    <h2>Part I: Instruction and Learning</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">How are our student subgroups performing relative to all other
                        students?</h3>
                    <Link to={locations.part01.sectionA.page1} className="button wide-action-button question-button">A.
                        How well are our students performing in ELA?</Link>
                    <Link to={locations.part01.sectionB.page1} className="button wide-action-button question-button">B.
                        How are our students performing in Mathematics?</Link>
                    <Link to={locations.part01.sectionC.page1} className="button wide-action-button question-button">C.
                        How has our schoolwide ELA/Math performance changed over time?</Link>
                </div>
            </div>


            <div className="content-block content-navigation-buttons">
                <Link to={locations.part01.sectionA.page1} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.intro.page1} className="button back-button">&#9664; BACK</Link>
            </div>

        </>
    );
}

export default Part01;

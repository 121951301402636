import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {animateScroll as scroll} from "react-scroll";

function ScrollToTop({history}) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            scroll.scrollToTop({
                duration: 150,
            });
        });
        return () => {
            unlisten();
        }
    }, []);

    return (null);
}

export default withRouter(ScrollToTop);
import React, {useLayoutEffect, useState} from "react";
import {Link} from "react-router-dom";
import ReflectionReport from "../../Components/Reflections/ReflectionReport";
import Banner from "../../Components/Common/Banner";
import locations from "../../Helpers/locations";

import {reflections} from "../../Components/Reflections/reflectionConstants";
import {setPageTitle} from "../../Helpers/setPageTitle";

function FinalReport() {
    const [clearReportCss, setClearReportCss] = useState("");
    const [reflectionData, setReflectionData] = useState([]);

    const handleClearReport = (event) => {
        event.preventDefault();
        if (clearReportCss === "" && window.confirm("Are you sure you want to clear all the report data?")) {
            for (const index in reflections)
                localStorage.removeItem(reflections[index].reflectionId);

            buildReflectionData();
        }
    };

    const buildReflectionData = () => {
        let tempReflectionData = [];
        let previousSectionTitle = "";
        for (const index in reflections) {
            const reflectionId = reflections[index].reflectionId;
            const curTextValue = localStorage.getItem(reflectionId) != null ? localStorage.getItem(reflectionId) : '';
            let currentSectionTitle = reflections[index].sectionTitle;
            if(currentSectionTitle !== previousSectionTitle)
                previousSectionTitle = currentSectionTitle;
            else
                currentSectionTitle = "";

            tempReflectionData.push({
                sectionTitle: currentSectionTitle,
                reflectionQuestion: reflections[index].reflectionQuestion,
                text: curTextValue.trim()
            });
        }

        setReflectionData(tempReflectionData)
    };

    useLayoutEffect(() => {
        setPageTitle("Data Navigation Tool Final Report");

        buildReflectionData();
    }, []);

    useLayoutEffect(() => {
        let disabledText = "";
        if (reflectionData.filter(f => f.text).length === 0)
            disabledText = "disabled";

        setClearReportCss(disabledText);
    }, [reflectionData]);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part I: Instruction and
                    Learning &gt; Section A.
                    &gt; <strong> Final Report </strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>Final Report</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <ReflectionReport reflectionData={reflectionData}/>
                    <button className="button save-button" onClick={() => window.print()}>Print Results</button>
                    <button className={`button save-button extra-horizontal-margin ${clearReportCss}`}
                            onClick={handleClearReport}>Clear Report Data
                    </button>
                </div>
            </div>
            <div className="content-block content-navigation-buttons">
			    <Link to={locations.part02.index} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part01.sectionC.page2} className="button back-button">&#9664; BACK</Link>
            </div>
        </>

    );
}

export default FinalReport;
import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import locations from "../../Helpers/locations";

const ParticipantList = ({ handleClearIndividual, participants, path }) => {
    return (
        <>
            {
                participants.map((person, index) => {
                    return (
                            <div className={"grid-x grid-margin-x small-extra-vertical-margin participant-list-container"} key={index}>
                                <div className={"cell medium-5"}>
                                    <li><Link to={locations.selfAssessment.individualReport.getPath(path, person.email)} >{person.name}</Link></li>
                                </div>
                                <div className={"cell auto"}>
                                    <a onClick={(event) => handleClearIndividual(event, person.email, person.name)}><span className={"icon-remove icon"} />Remove</a>
                                </div>
                            </div>
                    );
                })
            }
            {
                participants.length === 0 &&
                <p><i>No participants</i></p>
            }
        </>
    );
};

ParticipantList.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.object),
    handleClearIndividual: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired
};

export default ParticipantList;

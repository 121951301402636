import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {STICKY_HEADER as style} from "../../Helpers/styleconstants";
import locations from "../../Helpers/locations";
import planningGuide from "../../Data/Section 3 Planning Guide.docx"

function NavigationMenu({handleMenuClick}) {

    return (
        <div className={style.menuContainer}>
            <ul className={style.menuItems} onClick={handleMenuClick}>
                <li className={"clearfix"}>
                    <a href={"#"} className={"close-menu"}><span className={"icon-close"}></span> Menu</a>
                    <Link to="/"><span className="icon-home"></span> Home</Link>
                </li>
                <li>
                    <Link to={locations.selfAssessment.overview}>Self Assessment</Link>
                </li>
                <li>
                    <Link to={locations.intro.page1}>Data Navigation Tool</Link>
                    <ul>
                        <li><Link to={locations.part01.index}>Do our instructional practices ensure that all students in our school received high-quality opportunities to learn?</Link>
                            <ul>
                                <li><Link to={locations.part01.index}><span className="bold">Part I:</span>  Instruction and Learning</Link>
                                    <ul>
                                        <li><Link to={locations.part01.sectionA.page1}><span className="bold">A.</span> ELA Performance</Link></li>
                                        <li><Link to={locations.part01.sectionB.page1}><span className="bold">B.</span> Math Performance</Link></li>
                                        <li><Link to={locations.part01.sectionC.page1}><span className="bold">C.</span> Performance Over Time</Link></li>
                                    </ul>
                                </li>
                                <li><Link to={locations.finalReport}>Final Report</Link></li>
                            </ul>
                        </li>
                        <li><Link to={locations.part02.index}>What other local data might inform our perspective on this question?</Link>
                            <ul>
                                <li><Link to={locations.part02.index}><span className="bold">Part II:</span>  Context for Learning</Link>
                                    <ul>
                                        <li><Link to={locations.part02.sectionA.page1}><span className="bold">A.</span> Educator Effectiveness and Experience</Link></li>
                                        <li><Link to={locations.part02.sectionB.page1}><span className="bold">B.</span> Climate and Culture</Link></li>
                                        <li><Link to={locations.part02.sectionC.page1}><span className="bold">C.</span> Curriculum</Link></li>
                                        <li><Link to={locations.part02.sectionD.page1}><span className="bold">D.</span> Professional Learning</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href={planningGuide} target="_blank" rel="noreferrer noopener" download>Action Planning Guide</a>
                </li>
            </ul>
        </div>
    )
}

NavigationMenu.propTypes = {
    handleMenuClick: PropTypes.func.isRequired
};

export default NavigationMenu;

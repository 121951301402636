import PropTypes from "prop-types";
import React from "react";
import {assessmentStorage} from "./AssessmentLogic";
import Reflection from "./Reflection";
import SelfAssessmentIndicator from "./SelfAssessmentIndicator";
import {assessmentIndicators} from "../../Views/SelfAssessment/selfAssessmentConstants";

function SelfAssessmentSection({sectionTitle, path, category}) {

    const getAssessmentIndicators = () => {
        let indicators = [];
        for (let [index, value] of assessmentIndicators.entries()) {
            if (value.Category === sectionTitle) {
                indicators.push(<SelfAssessmentIndicator
                    key={index}
                    id={value.id}
                    indicatorText={value.Indicator}
                    path={path}
                />)
            }
        }

        return indicators;
    };


    return (
        <>
            {getAssessmentIndicators()}
            <Reflection path={path} reflectionId={assessmentStorage.getReflectionKey(category)}/>
        </>
    )
}

SelfAssessmentSection.propTypes = {
    sectionTitle: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
};


export default SelfAssessmentSection;

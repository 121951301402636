import React, {useState} from "react";
import PropTypes from "prop-types";
import {REFLECTIONS as style} from "../../Helpers/styleconstants";
import TextArea from "../Inputs/TextArea";

import {reflections} from "./reflectionConstants";
import {NotificationFactory} from "../Notification/NotificationFactory";

const notificationFactory = new NotificationFactory();

function Reflection({reflectionId}) {
    const curTextValue = localStorage.getItem(reflectionId) != null ? localStorage.getItem(reflectionId) : '';
    const [field, setField] = useState(curTextValue);
    const [fieldChanged, setFieldChanged] = useState(false);

    const handleChange = (e) => {
        localStorage.setItem(reflectionId, e.target.value);
        setField(e.target.value);
        setFieldChanged(true);
    };

    const handleBlur = () => {
        if(fieldChanged) {
            notificationFactory.createSuccess("Reflection saved!").notify();
            setFieldChanged(false);
        }
    };

    const reflection = reflections.find(r => r.reflectionId === reflectionId);

    if (!reflection)
        return null;

    return (
        <>
            <div className={style.reflection}>
                <label htmlFor={reflectionId} className={style.reflectionText}>
                    {reflection.reflectionQuestion}
                </label>
            </div>
            <TextArea
                id={reflectionId}
                name={reflectionId}
                value={field}
                onChange={handleChange}
                showLabel={false}
                onBlur={handleBlur}
            />
        </>
    )
}

Reflection.propTypes = {
    reflectionId: PropTypes.string.isRequired,
};

export default Reflection;
import React, {useLayoutEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {animateScroll as scroll} from "react-scroll";
import {NotificationFactory} from "../../../Components/Notification/NotificationFactory";
import SelfAssessmentDirections from "../../../Components/SelfAssessment/SelfAssessmentDirections";
import SelfAssessmentSection from "../../../Components/SelfAssessment/SelfAssessmentSection";
import {assessmentStorage, participantManager} from "../../../Components/SelfAssessment/AssessmentLogic";
import locations from "../../../Helpers/locations";
import {setPageTitle} from "../../../Helpers/setPageTitle";
import {assessmentIndicators, ReportType} from "../selfAssessmentConstants";

const notificationFactory = new NotificationFactory();

function AssessmentPage03() {
    const history = useHistory();

    const onSubmit = () => {

        const questionIds = assessmentIndicators
            .filter(f => f.Category === ReportType.SkillsAndKnowledge.title)
            .map((indicator) => indicator.id);

        if (!assessmentStorage.validateAllResponsesSelected(ReportType.Full.path, questionIds, participantManager.getCurParticipantId())) {
            notificationFactory.createError("Some ratings have not been selected. Please make sure to select a response to each one.").notify();
            scroll.scrollToTop({
                duration: 150,
            });
            return;
        }

        participantManager.resetCurParticipantId();
        history.push(locations.selfAssessment.selfAssessment.getPath(ReportType.Full.path));
    };

    useLayoutEffect(() => {
        setPageTitle("Full Self-Assessment Page 3");
    }, []);

    return (
        <>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Self Assessment &gt; <strong>Participant</strong>
                </div>

                <SelfAssessmentDirections/>

                <div className="content-block content-description text-center">
                    <h2>{ReportType.SkillsAndKnowledge.title}</h2>
                </div>
                <SelfAssessmentSection sectionTitle={ReportType.SkillsAndKnowledge.title} category={ReportType.SkillsAndKnowledge.path} path={ReportType.Full.path} />
            </div>
            <div className="content-block content-navigation-buttons">
                <Link to={locations.selfAssessment.page2} className="button back-button">&#9664; BACK</Link>
                <input type="button" onClick={onSubmit} className="button next-button" value="SUBMIT"/>
            </div>
        </>
    );
}

export default AssessmentPage03;

import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Reflection from '../../../../Components/Reflections/Reflection';
import Banner from "../../../../Components/Common/Banner";
import locations from "../../../../Helpers/locations";
import {setPageTitle} from "../../../../Helpers/setPageTitle";

function Part01_SectionA_Page03() {
    useLayoutEffect(() => {
        setPageTitle("Section A - ELA Performance");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part I: Instruction and
                    Learning &gt; <strong>Section A.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>A. ELA Performance</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">How well are our students performing in ELA?</h3>
                    <h4 className="reflectionHeader">Reflection questions</h4>
                    <Reflection reflectionId={"A-03-01"} />

                    <Reflection reflectionId={"A-03-02"} />

                    <Reflection reflectionId={"A-03-03"} />

                    <Reflection reflectionId={"A-03-04"} />
                </div>
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.part01.sectionB.page1} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part01.sectionA.page2} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part01_SectionA_Page03;

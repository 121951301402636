import React, {useLayoutEffect, useState} from "react";
import PropTypes from "prop-types";
import * as style from "../../Helpers/styleconstants";
import TextArea from "../Inputs/TextArea";
import {assessmentStorage, participantManager} from "./AssessmentLogic";

function Reflection({path, reflectionId}) {
    const [field, setField] = useState("");

    const handleChange = (e) => {
        let curParticipantId = participantManager.getCurParticipantId();
        assessmentStorage.saveResponse(path, reflectionId, curParticipantId, e.target.value);
        setField(e.target.value);
    };

    useLayoutEffect(() => {
        let curParticipantId = participantManager.getCurParticipantId();
        const curTextValue = assessmentStorage.getResponse(path, reflectionId, curParticipantId);
        setField(!curTextValue ? "" : curTextValue);
    }, [reflectionId]);

    return (
        <div className={style.ASSESSMENT.assessmentQuestion}>
            <div className={style.REFLECTIONS.reflection}>
                <label htmlFor={reflectionId} className={style.REFLECTIONS.reflectionText}>
                    Additional reflections and considerations
                </label>
            </div>
            <TextArea
                id={reflectionId}
                name={reflectionId}
                value={field}
                onChange={handleChange}
                showLabel={false}
            />
        </div>
    )
}

Reflection.propTypes = {
    path: PropTypes.string.isRequired,
    reflectionId: PropTypes.string.isRequired,
};

export default Reflection;
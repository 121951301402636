export const pages = [
  {
    "page" : "home",
    "image": "",
    "alt" : "Data Impact Home Page Banner",
    "text": "DATA IMPACT PROJECT"
  },
  {
    "page" : "dnt",
    "image": "",
    "alt" : "Data Navigation Tool",
    "text": "DATA NAVIGATION TOOL"
  },
  {
    "page" : "Part1",
    "image": "",
    "alt" : "Part I: Instruction and Learning",
    "text": "Part I: Instruction and Learning"
  },
  {
    "page" : "A-01",
    "image": "",
    "alt" : "A. 3rd Grade ELA Trend Data",
    "text": "A. 3rd Grade ELA Trend Data"
  },
  {
    "page" : "A-02",
    "image": "",
    "alt" : "A. ELA State & District Comparison",
    "text": "A. ELA State & District Comparison"
  },
  {
    "page" : "assessment",
    "image": "",
    "alt" : "Self Assessment",
    "text": "SELF ASSESSMENT"
  },
  {
    "page" : "demographic",
    "image": "",
    "alt" : "School Demographic Information",
    "text": "School Demographic Information"
  },
  {
    "page" : "sys",
    "image": "",
    "alt" : "Systems and Resources",
    "text": "Systems and Resources"
  },
  {
    "page" : "rank",
    "image": "",
    "alt" : "Rank Order of Categories",
    "text": "Rank Order of Categories"
  },
  {
    "page" : "final",
    "image": "",
    "alt" : "Final Report",
    "text": "FINAL REPORT"
  }
];
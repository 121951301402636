import React from "react";
import PropTypes from "prop-types";
import {CHECKBOXFIELD} from "../../Helpers/styleconstants";

const CheckBoxField = ({
                           name,
                           label,
                           checked,
                           showLabel,
                           error,
                           handleClick
                       }) => {

    let labelClass = "show-for-sr";
    if (showLabel) {
        labelClass = "";
    }

    const isChecked = (checked.toString().toLowerCase() === "true");

    return (
        <div className={CHECKBOXFIELD.wrapperClass}>
            <label htmlFor={name} className={CHECKBOXFIELD.label}>
                <input type="checkbox"
                       name={name}
                       id={name}
                       checked={isChecked}
                       className={CHECKBOXFIELD.checkboxField}
                       onChange={handleClick}/>
                <span className={labelClass}>{label}</span></label>
            {error && <div className="text-danger">{error}</div>}
        </div>
    );
};

CheckBoxField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    showLabel: PropTypes.bool.isRequired,
    error: PropTypes.string,
    handleClick: PropTypes.func
};

CheckBoxField.defaultProps = {
    showLabel: true,
    checked: false,
    handleClick: f => f
};

export default CheckBoxField;
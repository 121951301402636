import { isNullOrUndefined, isArray, isString } from "./typeUtilities";
import { deepCopyObject, objectHasProperty } from "./objectUtilities";

export function isInArray(array, string) {
	return !!array.filter((value) => value === string).length;
}

export function isArrayNullOrEmpty(array) {
	return isNullOrUndefined(array) || !(isArray(array) && array.length !== 0);
}

export function convertToArray(unknownType) {
	return isNullOrUndefined(unknownType) ?
		[] :
		isArray(unknownType) ?
			unknownType :
			isString(unknownType) ?
				unknownType.split(",") :
				[unknownType];
}

export function deepCopyArray(array) {
	if (isArrayNullOrEmpty(array)) return [];
	return deepCopyObject(array);
}

export function arraysEqual(a, b) {
	/*
		https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
        Array-aware equality checker:
        Returns whether arguments a and b are == to each other;
        however if they are equal-lengthed arrays, returns whether their
        elements are pairwise == to each other recursively under this
        definition.
    */
	if (a instanceof Array && b instanceof Array) {
		if (a.length!==b.length)  // assert same length
			return false;
		for(let i=0; i<a.length; i++)  // assert each element equal
			if (!arraysEqual(a[i],b[i]))
				return false;
		return true;
	} else {
		return a===b;  // if not both arrays, should be the same
	}
}

export function dynamicSort(property, SortDescending = false, baseProperty = "") {
	let sortOrder = SortDescending ? -1 : 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function(a, b) {
		let result = 0;
		if (a[property] === b[property] && baseProperty !== "") result = a[baseProperty] < b[baseProperty] ? -1 : a[baseProperty] > b[baseProperty] ? 1 : 0;
		else result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
}

export function filterObjectArrayByField(array, filter, field) {
	let result = [...array];
	if (filter && !isArrayNullOrEmpty(array)) {
		const searchStrings = filter.split(",").map((d) => d.trim());
		result = array.filter((ins) =>
			searchStrings.some(function(search) {
				return ins[field].toLowerCase().indexOf(search.toLowerCase()) >= 0;
			}),
		);
	}

	return result;
}

export function createArrayFromObject(object) {
	let result = [];
	for (let property in object) {
		if (objectHasProperty(object, property)) {
			result.push(object[property]);
		}
	}
	return result;
}

export function optionIndexInArray(array, option = "", wholeWord = true) {
	const optionValue = option.toLowerCase();

	for (let i = 0; i < array.length; i++) {
		const arrayValue = array[i].toLowerCase();
		if ((wholeWord && arrayValue === optionValue) || (!wholeWord && arrayValue.startsWith(optionValue))) return i;
	}

	return -1;
}

export function getDistinctValuesByObjectProperty(array, property) {
	return [
		...new Set(
			array.map((item) => {
				return item[property];
			}),
		),
	];
}
import React from "react";
import PropTypes from "prop-types";
import {reportingManager} from "../SelfAssessment/AssessmentLogic";

function SectionResources({avg, section}) {
    let displayValue = "N/A";
    if (avg !== null)
        displayValue = avg.toFixed(1);

    return (<>
        <div className="grid-x">
            <div className="cell score-cell shrink">
                <div className={`score-box ${reportingManager.getScoreAnalysis(avg, "score-lightblue").ColorCss}`}>
                    {displayValue}
                </div>
            </div>
            <div className="cell score-description-cell medium-auto large-auto">
                <h3>{section.title}</h3>
                <ul>
                    {
                        section.resources.map((resource, index) => {
                            return (
                                <li key={`${section.path}_${index}`}>
                                    <a className='color-white'
                                       href={resource.url}
                                       target="_blank" rel="noreferrer noopener">{resource.text ? resource.text : resource.url}</a>
                                </li>
                            );
                        })

                    }
                </ul>
                <br/>
            </div>
        </div>
    </>);

}

SectionResources.propTypes = {
    avg: PropTypes.number,
    section: PropTypes.object.isRequired
};

export default SectionResources;
export const reflections = [
    {
        "sectionTitle": "ELA Performance",
        "reflectionId" : "A-01",
        "reflectionQuestion": "What trends do you see in ELA data for students in 3rd grade? Consider cohort trends."
    },
    {
        "sectionTitle": "ELA Performance",
        "reflectionId" : "A-02",
        "reflectionQuestion": "What trends do you see in ELA data among subgroups schoolwide?  Consider proficiency levels by subgroup."
    },
    {
        "sectionTitle": "ELA Performance",
        "reflectionId" : "A-03-01",
        "reflectionQuestion": "In what areas, grade levels, and/or subgroups have you seen the most growth and improvement?"
    },
    {
        "sectionTitle": "ELA Performance",
        "reflectionId" : "A-03-02",
        "reflectionQuestion": "What strengths may have contributed to these trends?"
    },
    {
        "sectionTitle": "ELA Performance",
        "reflectionId" : "A-03-03",
        "reflectionQuestion": "What barriers or challenges may have contributed to these trends?"
    },
    {
        "sectionTitle": "ELA Performance",
        "reflectionId" : "A-03-04",
        "reflectionQuestion": "Considering the performance and population size, which subgroups provide the  greatest opportunity for improvement?"
    },
    {
        "sectionTitle": "Math Performance",
        "reflectionId" : "A-04-01",
        "reflectionQuestion": "What trends do you see in Math data for students in 4th/5th grade? Consider cohort trends."
    },
    {
        "sectionTitle": "Math Performance",
        "reflectionId" : "A-04-02",
        "reflectionQuestion": "How do the lowest 25% compare in Math Learning Gains to overall gains in 4th/5th grade?"
    },
    {
        "sectionTitle": "Math Performance",
        "reflectionId" : "A-05",
        "reflectionQuestion": "What trends do you see in ELA data among subgroups schoolwide?  Consider cohort trends and proficiency levels by subgroup."
    },
    {
        "sectionTitle": "Math Performance",
        "reflectionId" : "B-03-01",
        "reflectionQuestion": "What strengths may have contributed to these trends?"
    },
    {
        "sectionTitle": "Math Performance",
        "reflectionId" : "B-03-02",
        "reflectionQuestion": "What barriers or challenges may have contributed to these trends?"
    },
    {
        "sectionTitle": "Math Performance",
        "reflectionId" : "B-03-03",
        "reflectionQuestion": "Considering the performance and population size, which subgroups provide the greatest opportunity for improvement?"
    },
    {
        "sectionTitle": "Performance Over Time",
        "reflectionId" : "A-06-01",
        "reflectionQuestion": "Which of your school grade components showed the greatest decline over the past two years?"
    },
    {
        "sectionTitle": "Performance Over Time",
        "reflectionId" : "A-06-02",
        "reflectionQuestion": "What instructional and/or personnel factors may have contributed to this decline?"
    },
    {
        "sectionTitle": "Performance Over Time",
        "reflectionId" : "A-07-01",
        "reflectionQuestion": "Which of your school grade components (if any) are below the district average?"
    },
    {
        "sectionTitle": "Performance Over Time",
        "reflectionId" : "A-07-02",
        "reflectionQuestion": "Which of your school grade components (if any) are below the state average?"
    }
];
import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Banner from "../../../../Components/Common/Banner";
import LearningQuestionContext from "../../../../Components/LearningQuestionContext";
import locations from "../../../../Helpers/locations";
import {setPageTitle} from "../../../../Helpers/setPageTitle";

function Part02_SectionC_Page01() {
    useLayoutEffect(() => {
        setPageTitle("Section D - Professional Learning");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part II: Context for Learning &gt;
                    <strong>Section
                        D.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>D. Professional Learning</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">How do professional learning investments support a deliberate strategy
                        to close student achievement gaps?</h3>

                    <div className="question-container lightaquablue">
                        <ol>
                            <li>What professional learning was offered in the past year that directly engaged educators
                                in addressing instruction and/or classroom culture that results in inequitable student
                                outcomes?
                            </li>
                            <li>How much support have educators received over the past year around understanding and
                                using data that illuminates student subgroup gaps in instructional access and outcomes?
                            </li>
                            <li>How is professional learning distributed across teachers based on their experience
                                levels and their specific student needs?
                            </li>
                        </ol>
                        <LearningQuestionContext/>
                    </div>
                </div>
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.home} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part02.sectionC.page1} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part02_SectionC_Page01;

import React from "react";

function ScoreExplanation() {
    return (
        <div className={"score-table"}>
            <div className={"grid-x score-table-row"}>
                <div className={"cell score-table-cell medium-3 score-green"}>
                    <p><strong>Implementing</strong></p>
                    <p>We have a cohesive plan and we are making progress,
                        but we still have some refinements we plan to make.</p>
                </div>
                <div className={"cell score-table-cell medium-3 score-yellow"}>
                    <p><strong>Progressing</strong></p>
                    <p>We are making progress, but we are still navigating some challenges.</p>
                </div>
                <div className={"cell score-table-cell medium-3 score-orange"}>
                    <p><strong>Planning </strong></p>
                    <p>Our team is developing or has developed a plan of action. However, we have yet to implement our
                        plan.</p>
                </div>
                <div className={"cell score-table-cell medium-3 score-gray"}>
                    <p><strong>Beginning</strong></p>
                    <p>We are still in the early stages of this work.</p>
                </div>
            </div>
        </div>
    );
}

export default ScoreExplanation;
import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import LearningQuestionContext from "../../../../Components/LearningQuestionContext";
import {setPageTitle} from "../../../../Helpers/setPageTitle";
import image01 from '../../../../Images/09-01.png';
import image02 from '../../../../Images/09-02.png';
import Banner from "../../../../Components/Common/Banner";
import locations from "../../../../Helpers/locations";

function Part02_SectionA_Page01() {
    useLayoutEffect(() => {
        setPageTitle("Section A - Educator Experience");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">

                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part II: Context for Learning &gt; <strong>Section
                    A.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>A. Educator Effectiveness and Experience</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">How are highly effective teachers distributed across classrooms in our
                        school and students of different subgroups?</h3>

                    <div className='grid-x grid-margin-x'>
                        <div className='cell large-6'>
                            <a onClick={() => window.open(image01, "_blank")}><img src={image01} alt='Ineffective Educators'/></a>
                        </div>
                        <div className='cell large-6'>
                            <p>
                                This chart is an illustration of educator effectiveness data from the Qualifications and
                                Equity section of the state report card. The chart shows the count of ineffective
                                educators, no evaluation, total educators, and the percentage of ineffective educators
                                by educator type including teachers, principals, and other staff. &quot;Ineffective educator&quot;
                                is defined as a full-time teacher, principal, or other school leader who received a
                                summative performance evaluation rating of unsatisfactory per s. 1012.34, F.S. This
                                report is used to determine that low-income and minority students enrolled in schools
                                assisted under Title I, Part A are not served at disproportionate rates by ineffective
                                teachers, principals, and other school leaders as required by (ESEA section
                                1111(g)(1)(B)).
                            </p>
                        </div>
                    </div>


                    <div className="question-container lightergold">
                        <h4>Educator Qualifications Data</h4>
                        <p>
                            Your school’s report card allows you to view <strong>Educator Qualifications</strong>,
                            including experience, out-of-field teachers, and educator effectiveness.
                        </p>
                        <p>
                            To create this chart(s) for your school:
                        </p>
                        <ul>
                            <li>Go to your school report card on <a href="https://edudata.fldoe.org/"
                                                                    target="_blank" rel="noopener noreferrer">EduData</a></li>
                            <li>Click Educator Qualifications and Equity</li>
                        </ul>
                    </div>

                    <h3 className="text-center">How are inexperienced teachers distributed across students of different subgroups?</h3>
                    <div className='grid-x grid-margin-x'>
                        <div className='cell large-6'>
                            <p>
                                This chart is an illustration of educator experience data from the Qualifications and
                                Equity section of the state report card. The chart shows the count of inexperienced
                                educators, experienced educators, total educators, and the percentage of inexperienced
                                educators by educator type including teachers, principals, and other staff. The number
                                of years of experience for classroom teachers is calculated based on the related years
                                of teaching experience in Florida public, non-public and out-of-state non-public
                                schools.
                            </p>
                        </div>
                        <div className='cell large-6'>
                            <a onClick={() => window.open(image02, "_blank")}><img src={image02} alt='Educator Experience'/></a>
                        </div>
                    </div>

                    <p>The number of years of experience for administrators is calculated based on the related years of
                        experience as an administrator. &quot;Inexperienced educator&quot; is defined as a full-time teacher who
                        has been teaching less than four (4) years, a full-time teacher who holds a temporary
                        certificate, or a full-time administrator who has less than four (4) years of related
                        experience.This report is used to ensure that low-income and minority students enrolled in
                        schools assisted under Title I, Part A are not served at disproportionate rates by inexperienced
                        teachers, principals, and other school leaders as required by (ESEA section 1111(g)(1)(B)).</p>

                    <div className="question-container lightaquablue">
                        <h4>Deep Dive Questions</h4>
                        <p>
                            <em>Consider the following subgroup comparisons when completing the Deep Dive Questions:</em>
                        </p>
                        <div className='grid-x grid-margin-x'>
                            <div className='cell large-6'>
                                <ul>
                                    <li><em>SWD/non-SWD</em></li>
                                    <li><em>Black/White</em></li>
                                    <li><em>Hispanic/White</em></li>
                                </ul>
                            </div>
                            <div className='cell large-6'>
                                <ul>
                                    <li><em>Black/Hispanic</em></li>
                                    <li><em>ELL/non-ELL</em></li>
                                </ul>
                            </div>
                        </div>

                        <p>Do our highest risk students have access to experienced educators?</p>
                        <p>Do all of our student subgroups have access to experienced educators?</p>
                        <p>Do particular subgroups have more access than others?</p>
                        <p>Which teachers showed the greatest percentage of proficiency with students?</p>
                        <p>Does the student data align to what is observed in classroom walkthroughs?</p>
                        <p>What does the data tell us about where our teachers are struggling the most?</p>

                        <LearningQuestionContext/>
                    </div>
                </div>
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.part02.sectionB.page1} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part02.index} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part02_SectionA_Page01;

import PropTypes from "prop-types";
import React from "react";
import {RolesList} from "../../Views/SelfAssessment/selfAssessmentConstants";
import CheckBoxField from "../Inputs/CheckBoxField";
import TextField from "../Inputs/TextField";
import SelectField from "../Inputs/selectField";

function Demographics({config, errors, isSubmitted}) {
    return (
        <>
            <CheckBoxField
                label="Complete Assessment Anonymously"
                name="isAnonymous"
                checked={config.isAnonymous}
                showLabel={true}
                error={isSubmitted && errors.isAnonymous ? errors.isAnonymous : ""}
            />
            {
                !config.isAnonymous &&
                <>
                    <TextField
                        name="fName"
                        label="First Name"
                        value={config.fName}
                        showLabel={true}
                        maxLength={100}
                        onChange={f => f}
                        error={isSubmitted && errors.fName ? errors.fName : ""}
                    />
                    <TextField
                        name="lName"
                        label="Last Name"
                        value={config.lName}
                        showLabel={true}
                        maxLength={100}
                        onChange={f => f}
                        error={isSubmitted && errors.lName ? errors.lName : ""}
                    />
                    <TextField
                        name="email"
                        label="Email Address"
                        value={config.email}
                        showLabel={true}
                        maxLength={100}
                        onChange={f => f}
                        error={isSubmitted && errors.email ? errors.email : ""}
                    />
                    <TextField
                        name="district"
                        label="District"
                        value={config.district}
                        showLabel={true}
                        maxLength={100}
                        onChange={f => f}
                        error={isSubmitted && errors.district ? errors.district : ""}
                    />
                    <TextField
                        name="school"
                        label="School"
                        value={config.school}
                        showLabel={true}
                        maxLength={100}
                        onChange={f => f}
                        error={isSubmitted && errors.school ? errors.school : ""}
                    />
                </>
            }

            <SelectField
                name="role"
                label="Role"
                showLabel={true}
                error={isSubmitted && errors.role ? errors.role : ""}
                options={RolesList}
                value={config.role}
            />
        </>
    )

}

Demographics.propTypes = {
    config: PropTypes.shape({
        isAnonymous: PropTypes.bool,
        fName: PropTypes.string,
        lName: PropTypes.string,
        email: PropTypes.string,
        district: PropTypes.string,
        school: PropTypes.string,
        role: PropTypes.string,
    }),
    errors: PropTypes.shape({
        isAnonymous: PropTypes.string,
        fName: PropTypes.string,
        lName: PropTypes.string,
        email: PropTypes.string,
        district: PropTypes.string,
        school: PropTypes.string,
        role: PropTypes.string,
    }),
    isSubmitted: PropTypes.bool,
};

export default Demographics;

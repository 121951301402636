import { DefaultNotification } from "./DefaultNotification";
import * as config from "../Common/Constants/config";
import { NotificationTypes } from "./NotificationTypes";

export class DefaultError extends DefaultNotification {
	constructor(notificationAdapter) {
		const message = "There was an error while attempting to contact the server. If the problem persists, contact 850-245-0509.";
		super(notificationAdapter, NotificationTypes.ERROR, message);
	}
}

import React from "react";
import PropTypes from "prop-types";
import SectionResources from "./SectionResources";

function Resources({categoryAveragesForChart}) {
    const getResources = () => {
        let resources = [];

        for (const [index, category] of categoryAveragesForChart.entries()) {
            resources.push(<SectionResources key={`resources_${index}`} avg={category.average} section={category.section}/>);
        }

        return resources;
    };

    return (
        <>
            <div className="content-block content-description text-center">
                <h2>Recommended Resources</h2>
            </div>
            <div className="content-block content-questionnaire">
                <p>Below are the recommended tools and resources to learn more information about each category.</p>
                {getResources()}
            </div>
        </>
    );
}

Resources.propTypes = {
    categoryAveragesForChart: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Resources;
import React from 'react';
import PropTypes from "prop-types";
import {TEXTAREA as style} from "../../Helpers/styleconstants";

const TextArea = (config) => {
    config = Object.assign({}, textAreaDefaults(), config);
    return (
        <div className={style.wrapperClass}>
            {config.showLabel &&
            <label className={style.label} htmlFor={config.name}>{(config.isRequired) &&
            <span className="text-danger">* </span>}{config.label}</label>
            }
            <div className={style.input}>
                <textarea
                    id={config.id}
                    className={style.textArea}
                    name={config.name}
                    placeholder={config.placeholder}
                    value={config.value}
                    onChange={config.onChange}
                    readOnly={config.disabled}
                    tabIndex={config.tabIndex}
                    onBlur={config.onBlur}
                />
            </div>

            {config.error && <div className="text-danger">{config.error}</div>}
        </div>
    );
};

TextArea.propTypes = {
    config: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string.isRequired,
        showLabel: PropTypes.bool.isRequired,
        error: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        disabled: PropTypes.bool,
        isRequired: PropTypes.bool,
        tabIndex: PropTypes.number,

    })
};

function textAreaDefaults() {
    return {
        disabled: false,
        isRequired: false,
        label: "",
        showLabel: false,
        error: "",
        onBlur: () => {}
    };
}

export default TextArea;
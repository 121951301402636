import React from "react";
import {createArrayFromObject} from "../../Helpers/arrayUtilities";
import {createListFromObject} from "../../Helpers/utilities";

export const ReflectionId = "reflection";

export const ReportType = {
    Full: {
        path: "Full",
        title: "Full",
        resources: []
    },
    SystemsAndResources: {
        path: "SystemsAndResources",
        title: "Systems and Resources",
        resources: [
            {
                url: "https://www.carnegiefoundation.org/our-ideas/six-core-principles-improvement/",
                text: "Carnegie Improvement Science Principles"
            },
            {
                url: "https://drive.google.com/file/d/18UzZ0hQeUKhJ09hzVWwSP1bD38fOPsk8/view?usp=sharing",
                text: "K-5 Content-based Literacy Evaluation Rubric for Core Instruction"
            },
            {
                url: "https://www.sanfordharmony.org/",
                text: "Sanford Harmony"
            }
        ]
    },
    BehaviorsAndMindset: {
        path: "BehaviorsAndMindset",
        title: "Behaviors and Mindset",
        resources: [
            {
                url: "https://tntp.org/publications/view/student-experiences/the-opportunity-myth",
                text: "TNTP: Opportunity Myth"
            },
            {
                url: "https://drive.google.com/file/d/17-VU-xmenE8CFlUGFZsZ_mKtBc2oF_Gu/view?usp=sharing",
                text: "ASCD: Equity Literacy for All"
            },
            {
                url: "https://drive.google.com/open?id=1n3-sZP5Oq6FNWyDZT_collmg1ZXoVVFf",
                text: "Perception Institute: The Science of Equality, Vol 1"
            },
            {
                url: "https://drive.google.com/file/d/1J9CqNCCredS7wshFOoJZYFahAkl2SHn2/view?usp=sharing",
                text: "American Educator: Advancing Our Students’ Language and Literacy"
            },
            {
                url: "https://www.tolerance.org/magazine/equity-work-should-start-from-the-top",
                text: "Equity Work Should Start From the Top"
            },
            {
                url: "https://learningforward.org/wp-content/uploads/2018/10/beyond-random-acts-of-equity.pdf",
                text: "Beyond Random Acts of Equity"
            }
        ]
    },
    SkillsAndKnowledge: {
        path: "SkillsAndKnowledge",
        title: "Skills and Knowledge",
        resources: [
            {
                url: "https://west.edtrust.org/data-equity-walk-toolkit/",
                text: "EdTrust Data Equity Walks"
            },
            {
                url: "https://www2.heartlanded.org/",
                text: "Heartland Consortium"
            },
            {
                url: "https://visible-learning.org/hattie-ranking-influences-effect-sizes-learning-achievement/",
                text: "Hattie Ranking: 252 Influences And Effect Sizes Related To Student Achievement"
            },
            {
                url: "https://www.tolerance.org/magazine/starting-with-ourselves-preparing-for-tough-classroom-conversations",
                text: "Preparing for Tough Classroom Conversations"
            },
            {
                url: " https://www.gettingsmart.com/2018/08/personalize-learning-and-build-agency-by-using-the-4-plc-questions/",
                text: "Personalize Learning and Build Agency By Using the 4 PLC Questions"
            },
            {
                url: "http://ectacfl.net/",
                text: "ECTAC Florida"
            }
        ]
    },
};

export const ReportTypeList = createArrayFromObject(ReportType);

export const Roles = {
    AP: "AP",
    Principal: "Principal",
    Coach: "Coach",
    ThirdGradeTeacher: "3rd Grade Teacher",
    FourthGradeTeacher: "4th Grade Teacher",
    FifthGradeTeacher: "5th grade Teacher",
    TeacherOther: "Teacher: Other",
    Other: "Other",
};

export const RolesList = createListFromObject(Roles);

export const assessmentButton = [
    {
        "text": "We need to think this through",
        "value": 0
    },
    {
        "text": "We have a plan, but haven't kicked off",
        "value": 1
    },
    {
        "text": "This is a work in progress, but we've run into a few challenges",
        "value": 2
    },
    {
        "text": "We're getting there",
        "value": 3
    }
];


export const assessmentIndicators = [
    {
        "id": 1,
        "order": 1,
        "Category": ReportType.SystemsAndResources.title,
        "indicatorOrder": 1,
        "Indicator": "Our school has <strong>established processes and procedures</strong> for analyzing and asking questions about our data."
    },
    {
        "id": 2,
        "order": 1,
        "Category": ReportType.SystemsAndResources.title,
        "indicatorOrder": 2,
        "Indicator": "Our school has a systematic approach to providing <strong>coaching and/or other professional development opportunities</strong> to staff who need support with data."
    },
    {
        "id": 3,
        "order": 1,
        "Category": ReportType.SystemsAndResources.title,
        "indicatorOrder": 3,
        "Indicator": "Our school has established <strong>a shared understanding</strong> of our data systems and processes."
    },
    {
        "id": 4,
        "order": 1,
        "Category": ReportType.SystemsAndResources.title,
        "indicatorOrder": 4,
        "Indicator": "We have access to <strong>timely</strong> data to inform improvement plans and instructional practice."
    },
    {
        "id": 5,
        "order": 1,
        "Category": ReportType.SystemsAndResources.title,
        "indicatorOrder": 5,
        "Indicator": "We have access to <strong>coherent</strong> data to inform improvement plans and instructional practice."
    },
    {
        "id": 6,
        "order": 1,
        "Category": ReportType.SystemsAndResources.title,
        "indicatorOrder": 6,
        "Indicator": "We have access to <strong>accurate</strong> data to inform improvement plans and instructional practice."
    },
    {
        "id": 7,
        "order": 1,
        "Category": ReportType.SystemsAndResources.title,
        "indicatorOrder": 7,
        "Indicator": "We have <strong>access to equity data</strong> (disaggregated by subgroups such as race, socio-economic status, language, etc.) to inform improvement plans and instructional practice."
    },
    {
        "id": 8,
        "order": 1,
        "Category": ReportType.SystemsAndResources.title,
        "indicatorOrder": 8,
        "Indicator": "School leaders <strong>provide opportunities for staff to contribute</strong> and give input on data-driven decision-making processes at our school."
    },
    {
        "id": 9,
        "order": 2,
        "Category": ReportType.BehaviorsAndMindset.title,
        "indicatorOrder": 1,
        "Indicator": "Our school has established data analysis practices that <strong>drive instructional decision-making and improvement</strong>."
    },
    {
        "id": 10,
        "order": 2,
        "Category": ReportType.BehaviorsAndMindset.title,
        "indicatorOrder": 2,
        "Indicator": "Our school leaders have <strong>a vision for data-driven instruction</strong> that supports improved outcomes for all students."
    },
    {
        "id": 11,
        "order": 2,
        "Category": ReportType.BehaviorsAndMindset.title,
        "indicatorOrder": 3,
        "Indicator": "Our school leadership <strong>communicates and prioritizes data-driven instruction</strong> throughout the school."
    },
    {
        "id": 12,
        "order": 2,
        "Category": ReportType.BehaviorsAndMindset.title,
        "indicatorOrder": 4,
        "Indicator": "Our school leaders <strong>use data to identify targeted strategies</strong> aimed at reducing inequities for subgroups in our school."
    },
    {
        "id": 13,
        "order": 2,
        "Category": ReportType.BehaviorsAndMindset.title,
        "indicatorOrder": 5,
        "Indicator": "We regularly <strong>use data to inform</strong> our improvement plans and <strong>instructional practices.</strong>"
    },
    {
        "id": 14,
        "order": 2,
        "Category": ReportType.BehaviorsAndMindset.title,
        "indicatorOrder": 6,
        "Indicator": "We regularly <strong>use data to ensure</strong> our <strong>instructional practices are equitable</strong> across student subgroups."
    },
    {
        "id": 15,
        "order": 2,
        "Category": ReportType.BehaviorsAndMindset.title,
        "indicatorOrder": 7,
        "Indicator": "Our school leaders <strong>provide feedback to staff on improvement plans and instructional practices</strong> as they relate to student data."
    },
    {
        "id": 16,
        "order": 2,
        "Category": ReportType.BehaviorsAndMindset.title,
        "indicatorOrder": 8,
        "Indicator": "Our <strong>students regularly engage with their own data</strong> to track their progress and develop action plans."
    },
    {
        "id": 17,
        "order": 3,
        "Category": ReportType.SkillsAndKnowledge.title,
        "indicatorOrder": 1,
        "Indicator": "We have access to <strong>coaching opportunities</strong> to help increase our knowledge and use of data."
    },
    {
        "id": 18,
        "order": 3,
        "Category": ReportType.SkillsAndKnowledge.title,
        "indicatorOrder": 2,
        "Indicator": "We have access to <strong>professional development opportunities</strong> to help increase our data knowledge and capacity."
    },
    {
        "id": 19,
        "order": 3,
        "Category": ReportType.SkillsAndKnowledge.title,
        "indicatorOrder": 3,
        "Indicator": "We know how to <strong>analyze data to guide planning and inform instructional practices</strong>."
    },
    {
        "id": 20,
        "order": 3,
        "Category": ReportType.SkillsAndKnowledge.title,
        "indicatorOrder": 4,
        "Indicator": "We know how to <strong>access equity data</strong> (disaggregated by subgroups such as race, socio-economic status, language, etc.) available to our school."
    },
    {
        "id": 21,
        "order": 3,
        "Category": ReportType.SkillsAndKnowledge.title,
        "indicatorOrder": 5,
        "Indicator": "We have the <strong>skills and expertise to analyze equity data</strong> (disaggregated by subgroups such as race, socio-economic status, language, etc.) to inform improvement plans and instructional practices."
    }
];
import React from 'react';
import PropTypes from "prop-types";
import {TEXTFIELD as style} from "../../Helpers/styleconstants";

const TextField = ( config ) => {
    config = Object.assign({}, textFieldDefaults(), config);

    return (
        <div className={style.wrapperClass}>
            <label className={style.label} htmlFor={config.name}>{ (config.isRequired) && <span className="text-danger">* </span>}{config.label}</label>
            <div className={style.input} >
                <input id = {config.name} className={style.textField}  type={config.inputType}
                       name={config.name}
                       placeholder={config.placeholder}
                       value={config.value}
                       onChange={config.onChange}
                       disabled={config.disabled}
                       maxLength={config.maxLength}
					   tabIndex={config.tabIndex}

                />
            </div>

            {config.error && <div className="text-danger">{config.error}</div>}
        </div>
    );
};

TextField.propTypes = {
    config: PropTypes.shape({
		inputType: PropTypes.string,
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string.isRequired,
        showLabel: PropTypes.bool.isRequired,
        error: PropTypes.string,
		onChange: PropTypes.func,
        disabled: PropTypes.bool,
        maxLength: PropTypes.number.isRequired,
        isRequired: PropTypes.bool,
		tabIndex: PropTypes.number,

    })
};

function textFieldDefaults() {
    return {
        inputType: "text",
		isRequired: false,
		label: "",
		maxLength: 200,
		showLabel: false,
		disabled: false,
		error: "",
		textFieldType: ""
    };
}

export default TextField;
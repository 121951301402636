import React from "react";
import PropTypes from "prop-types";
import {reportingManager} from "../SelfAssessment/AssessmentLogic";

function ReportSectionResponse({average, questionDataIndicator}) {
    const getResponseValue = () => {
        let displayValue = "N/A";

        if (average !== null) {
            displayValue = average.toFixed(1);
        }

        return displayValue;
    };

    return (
        <div className="grid-x">
            <div className="cell score-cell shrink">
                <div className={`score-box ${reportingManager.getScoreAnalysis(average, "score-lightblue").ColorCss}`}>
                    {getResponseValue()}
                </div>
            </div>
            <div className="cell score-description-cell medium-auto large-auto">
                <p dangerouslySetInnerHTML={{__html: questionDataIndicator}}></p>
            </div>
        </div>
    );
}

ReportSectionResponse.propTypes = {
    average: PropTypes.number,
    questionDataIndicator: PropTypes.string.isRequired,
};

export default ReportSectionResponse;
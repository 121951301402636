import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Banner from "../../../../Components/Common/Banner";
import LearningQuestionContext from "../../../../Components/LearningQuestionContext";
import locations from "../../../../Helpers/locations";
import {setPageTitle} from "../../../../Helpers/setPageTitle";

function Part02_SectionC_Page01() {
    useLayoutEffect(() => {
        setPageTitle("Section C - Curriculum Access");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part II: Context for Learning &gt;
                    <strong>Section
                        C.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>C. Curriculum Access</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">Do all students have access to high-quality curriculum?</h3>
                    <div className="question-container lightaquablue">
                        <ol>
                            <li>Which of our classrooms have access to and use high-quality instructional materials? How
                                does this align with student subgroup proportions in each classroom?
                            </li>
                            <li>Which student groups are least likely to receive instruction grounded in high-quality
                                materials?
                            </li>
                            <li>Do our core curricular materials include guidance for teachers around supporting ELL
                                students and students with special needs to learn grade-level content?
                            </li>
                            <li>In the accelerated courses and/or gifted & talented programming we offer, are there
                                student subgroup gaps in identification or participation?
                            </li>
                            <li>What teaching strategies are used to address the standards?</li>
                            <li>How do we ensure that our tasks are aligned to standards?</li>
                        </ol>
                        <LearningQuestionContext/>
                    </div>
                </div>
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.part02.sectionD.page1} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part02.sectionB.page2} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part02_SectionC_Page01;

import React from "react";
import PropTypes from "prop-types";
import ScoreExplanation from "../../Views/SelfAssessment/ScoreExplanation";
import {RolesList} from "../../Views/SelfAssessment/selfAssessmentConstants";
import ChartReportTableRow from "./ChartReportTableRow";

function RoleGrid({sections, path}) {
    const totalColumns = 12/ (sections.length + 1);
    const columnsCss = `cell score-table-cell large-${totalColumns} medium-${totalColumns}`;

    return (
        <>
            <div className="content-block content-description text-center">
                <h2>Responses by Role</h2>
            </div>
            <div className="content-block content-questionnaire">

                <p>Below you will see the average category score by participant role.</p>

                <ScoreExplanation/>

                <div className="score-table">
                    <div className="grid-x score-table-row">
                        <div className={columnsCss}>
                            <p>
                                Role
                            </p>
                        </div>
                        {
                            sections.map((section, index) => {
                                return (
                                    <div key={`catAvg_${index}`} className={columnsCss}>
                                        <p>{section.title}</p>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {
                        RolesList.map((role, index) => {
                            return (
                                <ChartReportTableRow
                                    key={`${role.text}_${index}`}
                                    role={role.text}
                                    sections={sections}
                                    path={path}
                                    columnsCss={columnsCss}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </>);

}

RoleGrid.propTypes = {
    sections: PropTypes.arrayOf(PropTypes.object).isRequired,
    path: PropTypes.string.isRequired,
};

export default RoleGrid;
import React from "react";
import PropTypes from "prop-types";
import {BANNER as style} from "../../Helpers/styleconstants";
import {pages} from "./Constants/Banner.js";

function Banner({page}) {
    let pageDetails = pages.find(p => p.page === page);

    if (!pageDetails)
        pageDetails = pages.find(p => p.page === "home");

    if (!pageDetails)
        return null;

    return (
        <>
            <div className={style.bannerContainer}>
                {
                    page === "home" &&
                    <h1 className="homePageAdditionalBannerText">Florida Department of Education</h1>
                }
                <div className={style.bannerText}>
                    {pageDetails.text}
                </div>
            </div>
        </>
    )
}

Banner.propTypes = {
    page: PropTypes.string,
};

export default Banner;
import React from "react";
import PropTypes from "prop-types";
import {ASSESSMENT as style} from "../../Helpers/styleconstants";
import SelfAssessmentButtonContainer from "./SelfAssessmentButtonContainer";

function SelfAssessmentIndicator({id, indicatorText, path}) {
    return (
        <>
            <div className={style.assessmentQuestion}>
                <p dangerouslySetInnerHTML={{__html: indicatorText}}></p>
                <div className={style.assessmentButtonWrapper}>
                    <SelfAssessmentButtonContainer
                        questionId={id}
                        path={path}
                    />
                </div>
            </div>
        </>

    )
}

SelfAssessmentIndicator.propTypes = {
    id: PropTypes.number.isRequired,
    indicatorText: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
};

export default SelfAssessmentIndicator;
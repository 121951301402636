import React from "react";
import {FOOTER as style} from "../../Helpers/styleconstants";
import {supportEmail} from "./Constants/config";

export const Footer = () => (
   <div className={style.footerContainer}>
       <div className={style.footerContent}>
           <p>
               All rights reserved to Florida Dept. of Education 2020 ©<br/>
               Florida Department of Education; Division of Public Schools; 850-245-0509
           </p>
       </div>
   </div>
);
import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Reflection from "../../../../Components/Reflections/Reflection";
import {setPageTitle} from "../../../../Helpers/setPageTitle";
import image02 from '../../../../Images/07-02.png';
import Banner from "../../../../Components/Common/Banner";
import locations from "../../../../Helpers/locations";

function Part01_SectionC_Page02() {
    useLayoutEffect(() => {
        setPageTitle("Section C - Schoolwide ELA/Math Performance Over Time, Page 2");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part I: Instruction and
                    Learning &gt; <strong>Section
                    C.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>C. Performance Over Time</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3>How are we performing relative to the rest of our district and the state?</h3>
                    <div className='grid-x grid-margin-x'>
                        <div className='cell large-6'>
                            <a onClick={() => window.open(image02, "_blank")}><img src={image02} alt='School Data'/></a>
                        </div>
                        <div className='cell large-6'>
                            <p className="">
                                This chart shows the percentages and graphic representation of school grade components
                                compared to the school, district, and state averages for similar school types
                                (elementary, high school, or combination schools). It shows a comparative view of
                                the data over a two-year period.
                            </p>
                        </div>
                    </div>

                    <div className="question-container lightergold">
                        <h4>District & State Comparison</h4>
                        <p><a href="https://www.floridacims.org/" target="_blank"
                              rel="noopener noreferrer">CIMS</a> allows you to view
                            comparative data for the district and state for the past two years.</p>
                        <p>This information is included in your Schoolwide Improvement Plan (SIP) under Part II: Needs
                            Assessment/Analysis. </p>
                    </div>

                    <h4 className="reflectionHeader">Reflection questions</h4>
                    <Reflection reflectionId={"A-07-01"} />

                    <Reflection reflectionId={"A-07-02"} />
                </div>
            </div>


            <div className="content-block content-navigation-buttons">
                <Link to={locations.finalReport} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part01.sectionC.page1} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part01_SectionC_Page02;

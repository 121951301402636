import React, {useState, useLayoutEffect} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import Banner from "../../Components/Common/Banner";
import {participantManager} from "../../Components/SelfAssessment/AssessmentLogic";
import ParticipantList from "../../Components/SelfAssessment/ParticipantList";
import locations from "../../Helpers/locations";
import {setPageTitle} from "../../Helpers/setPageTitle";
import {assessmentIndicators, ReportType} from "./selfAssessmentConstants";
import SelfAssessmentGuidance from "../../Data/SelfAssessmentGuidance.pdf";

const SelfAssessment = () => {
    const history = useHistory();
    const {...params} = useParams();
    const [reportType, setReportType] = useState(null);
    const [noParticipantsCss, setNoParticipantsCss] = useState("");
    const [participants, setParticipants] = useState([]);

    const startReport = () => {
        participantManager.resetCurParticipantId();
        history.push(locations.selfAssessment.page1.getPath(reportType.path));
    };

    const getCategoryQuestionIds = (category) => {
        if (category === ReportType.Full.title)
            return assessmentIndicators.map(m => m.id);

        return assessmentIndicators.filter(f => f.Category === category).map(m => m.id);
    };

    const clearData = (event) => {
        event.preventDefault();
        if (participants.length > 0) {
            if (window.confirm(`Are you sure you want to clear all the ${reportType.title} Self-Assessment data?`)) {

                participantManager.removeCategoryData(reportType.path, getCategoryQuestionIds(reportType.title));
                setNoParticipantsCss("disabled");
                setParticipants([]);
            }
        }
    };

    const viewReport = (event) => {
        event.preventDefault();
        if (participants.length > 0) {
            history.push(locations.selfAssessment.report.getPath(reportType.path));
        }
    };

    const handleClearIndividual = (event, pId, fullName) => {
        event.preventDefault();

        if (window.confirm(`Are you sure you want to clear all the ${reportType.title} Self-Assessment data for ${fullName}?`)) {

            participantManager.removeCategoryData(reportType.path, getCategoryQuestionIds(reportType.title), pId);
            getParticipantGroupList();
        }
    };

    const getParticipantGroupList = () => {
        if(!reportType)
            return;

        let pIds = participantManager.getParticipantIds(reportType.path);

        let tempParticipants = [];
        for (let i = 0; i < pIds.length; i++) {
            let pId = pIds[i];
            let obj = participantManager.getParticipantData(reportType.path, pId);
            if (obj !== participantManager.NO_DATA) {
                let name = obj.fName + " " + obj.lName;
                tempParticipants.push({
                    name: name,
                    email: obj.email
                });
            }
        }

        setParticipants(tempParticipants);

        let buttonCss = "";
        if (tempParticipants.length === 0)
            buttonCss = "disabled";

        setNoParticipantsCss(buttonCss);
    };

    useLayoutEffect(() => {
        const report = ReportType[params.reportType] ? ReportType[params.reportType] : ReportType.Full;
        setReportType(report);
        setPageTitle(`${report.title} Overview`);
    }, [params]);

    useLayoutEffect(() => {
        getParticipantGroupList();
    }, [reportType]);

    if (!reportType)
        return null;

    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; <strong>Self Assessment</strong>
                </div>

                <div className="content-block content-description">
                    <div className={"grid-x grid-margin-x grid-margin-y"}>
                        <div className={"cell large-10 medium-9 small-12"}>
                            <p>To begin the {reportType.title} Self-Assessment, select &quot;Start Assessment&quot;.
                                Once at least one
                                team member has
                                completed the self assessment, the final report will become available below. To clear
                                participant data from this assessment,
                                press the clear data button at the bottom of the screen.
                            </p>
                        </div>
                        <div className={"cell large-2 medium-3 small-12 align-center"}>
                            <a href={SelfAssessmentGuidance} target="_blank" rel="noreferrer noopener" download
                               className={"icon-download-container"}><span
                                className={"icon-download"}></span> Download<br/><strong>Facilitation Guidance</strong></a>
                        </div>
                    </div>
                    <div className={"grid-x grid-margin-y"}>
                        <div className={"cell"}>
                            <input type={"button"} onClick={startReport}
                                   className="button light-action-button wide-action-button "
                                   value={"Start Assessment"}/>
                        </div>
                    </div>
                </div>
                <div className="content-block content-questionnaire">
                    <div className="grid-x grid-margin-x grid-margin-y">
                        <div className="cell large-6 medium-7">
                            <p><u>Participants</u></p>
                            <ParticipantList participants={participants} handleClearIndividual={handleClearIndividual}
                                             path={reportType.path}/>
                        </div>
                        <div className="cell large-6 medium-5">
                            <div>
                                <input type="button" onClick={viewReport}
                                       className={`button light-action-button wide-action-button ${noParticipantsCss}`}
                                       value={"View Report"}/>
                            </div>
                            <div>
                                <input type="button" onClick={clearData}
                                       className={`button light-action-button wide-action-button ${noParticipantsCss}`}
                                       value={`Clear ${reportType.title} Data`}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-block content-navigation-buttons">
                <Link to={locations.selfAssessment.overview} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
};

export default SelfAssessment;

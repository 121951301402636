import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Reflection from "../../../../Components/Reflections/Reflection";
import {setPageTitle} from "../../../../Helpers/setPageTitle";
import image01 from '../../../../Images/05-01.png';
import image02 from '../../../../Images/06-01.png';
import Banner from "../../../../Components/Common/Banner";
import locations from "../../../../Helpers/locations";

function Part01_SectionB_Page02() {
    useLayoutEffect(() => {
        setPageTitle("Section B - Math State & District Comparison");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">

                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part I: Instruction and
                    Learning &gt; <strong>Section
                    B.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>B. Math State & District Comparison</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">How are we performing relative to the rest of our district and the
                        state?</h3>
                    <p>
                        <a onClick={() => window.open(image01, "_blank")}><img src={image01}
                                                                               alt='2018-19 Mathematics Assessment Results by Achievement Level'/></a>
                    </p>
                    <p>
                        <a className='color-white' href="https://edudata.fldoe.org/" target="_blank"
                           rel="noopener noreferrer">EduData</a> allows
                        you to view achievement data for subgroups (including SWDs, ELLs, and Economically Disadvantaged
                        students). It also shows comparative data for the district and state.
                        EdStats allows you to see overall proficiency for your school by grade level for the past 5
                        years. This allows you to view the performance of specific cohorts over a three year window.
                    </p>

                    <div className="question-container lightergold">
                        <h4>Access Your SUBGROUP DATA</h4>
                        <p>To create this chart(s) for your school:</p>
                        <ul>
                            <li>Go to <a href="https://edudata.fldoe.org/" target="_blank"
                                         rel="noopener noreferrer">EduData</a> and access your
                                school’s report card
                            </li>
                            <li>Click View School Performance Components</li>
                            <li>Click Assessments, Academic Achievement, Growth, and Participation</li>
                            <li>Click Assessment Chart Creation</li>
                            <li>Adjust the filters according to the data you want to view</li>
                        </ul>
                    </div>

                    <h4 className="reflectionHeader">Reflection questions</h4>

                    <Reflection reflectionId={"A-05"}/>

                    <h3 className="text-center">How well are we catching up 4th & 5th graders who are below proficiency
                        in Math?</h3>

                    <div className='grid-x grid-margin-x'>
                        <div className='cell large-6'>
                            <a onClick={() => window.open(image02, "_blank")}><img src={image02}
                                                                                   alt='Needs Assessment/Analysis'/></a>
                        </div>
                        <div className='cell large-6'>
                            <p>
                                This chart depicts Math proficiency scores for grades 3 through 5 over a two-year
                                period. It shows the percent of students who are proficient by grade level within
                                the school in order to compare to the district and state proficiency averages. It also
                                allows you to compare proficiency by grade within the school over a two-year period.
                                This data is raw and includes all student who tested at the school, it is not school
                                grade data.
                            </p>
                        </div>
                    </div>


                    <div className="question-container lightergold">
                        <h4>4th & 5th Grade Performance Data</h4>
                        <p>
                            <a href="https://www.floridacims.org/" target="_blank"
                               rel="noopener noreferrer">CIMS</a> allows you to view
                            proficiency data for grades 3-5. This information is
                            included in your Schoolwide Improvement Plan (SIP) under Part II:
                            Needs Assessment/Analysis.
                        </p>
                    </div>
                </div>
            </div>


            <div className="content-block content-navigation-buttons">
                <Link to={locations.part01.sectionB.page3} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part01.sectionB.page1} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part01_SectionB_Page02;

import { isArrayNullOrEmpty } from "./arrayUtilities";

export function isObjectNullOrEmpty(obj) {
    return !(obj && Object.getOwnPropertyNames(obj).length !== 0);
}

export function objectHasProperty(object, property) {
    return !isObjectNullOrEmpty(object) && !!Object.prototype.hasOwnProperty.call(object, property);
}

export function deepCopyObject(object) {
    if (!object) return {};
    return JSON.parse(JSON.stringify(object));
}

export function createObjectFromArray(array) {
    let object = {};

    if (isArrayNullOrEmpty(array)) return object;

    array.map((item) => {
        return (object = Object.assign(object, { [item]: item }));
    });

    return object;
}

export function getPropertyName(object, value) {
    let listPropertyNames = Object.keys(object);
    return listPropertyNames.find((f) => object[f] === value);
}

export function getPropertyValue(object, property) {
    return objectHasProperty(object, property) ? object[property] : undefined;
}

import React from "react";
import PropTypes from "prop-types";
import {REFLECTIONS as style} from "../../Helpers/styleconstants";
import {convertLineBreaksToBreakTags} from "../../Helpers/utilities";

function ReflectionReport({reflectionData}) {
    return (
        <>
            {
                reflectionData.map((reflection, index) => {
                    return (
                        <div key={`report_${index}`}>
                            { reflection.sectionTitle && <h3>{reflection.sectionTitle}</h3> }
                            <p className={style.reflectionText}><strong>{reflection.reflectionQuestion}</strong></p>
                            <p className={style.reflectionResp} dangerouslySetInnerHTML={{__html: convertLineBreaksToBreakTags(reflection.text)}}></p>
                        </div>
                    );
                })
            }
        </>
    );
}

ReflectionReport.propTypes = {
    reflectionData: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ReflectionReport;

import React from 'react';
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import ScrollToTop from "./Components/Common/ScrollToTop";
import {APP_ENTRY as style} from "./Helpers/styleconstants";
import locations from "./Helpers/locations";
import StickyHeader from "./Components/Common/StickyHeader";
import {Footer} from "./Components/Common/Footer";
import Home from "./Views/Home";
import Intro01 from "./Views/DataNavigationTool/Intro/01";
import Intro02 from "./Views/DataNavigationTool/Intro/02";
import Overview from "./Views/SelfAssessment/Overview";
import SelfAssessment from "./Views/SelfAssessment/SelfAssessment";
import SelfAssessmentReport from "./Views/SelfAssessment/Report";
import SelfAssessmentReportChart from "./Views/SelfAssessment/ReportChart";
import AssessmentPage01 from "./Views/SelfAssessment/Assessment/01";
import AssessmentPage02 from "./Views/SelfAssessment/Assessment/02";
import AssessmentPage03 from "./Views/SelfAssessment/Assessment/03";
import Part01 from "./Views/DataNavigationTool/Part01";
import Part01_SectionA_01 from "./Views/DataNavigationTool/Part01/SectionA/01";
import Part01_SectionA_02 from "./Views/DataNavigationTool/Part01/SectionA/02";
import Part01_SectionA_03 from "./Views/DataNavigationTool/Part01/SectionA/03";
import Part01_SectionB_01 from "./Views/DataNavigationTool/Part01/SectionB/01";
import Part01_SectionB_02 from "./Views/DataNavigationTool/Part01/SectionB/02";
import Part01_SectionB_03 from "./Views/DataNavigationTool/Part01/SectionB/03";
import Part01_SectionC_01 from "./Views/DataNavigationTool/Part01/SectionC/01";
import Part01_SectionC_02 from "./Views/DataNavigationTool/Part01/SectionC/02";
import Part02 from "./Views/DataNavigationTool/Part02/";
import Part02_SectionA_01 from "./Views/DataNavigationTool/Part02/SectionA/01";
import Part02_SectionB_01 from "./Views/DataNavigationTool/Part02/SectionB/01";
import Part02_SectionB_02 from "./Views/DataNavigationTool/Part02/SectionB/02";
import Part02_SectionC_01 from "./Views/DataNavigationTool/Part02/SectionC/01";
import Part02_SectionD_01 from "./Views/DataNavigationTool/Part02/SectionD/01";
import FinalReport from "./Views/DataNavigationTool/FinalReport";
import withTracker from "./withTracker";

function App() {

    return (
        <Router>
            <div className={style.content} role="main">
                <ScrollToTop/>
                <StickyHeader/>
                <Switch>
                    <Route path={locations.intro.page1} component={withTracker(Intro01)}/>
                    <Route path={locations.intro.page2} component={withTracker(Intro02)}/>

                    <Route path={locations.part01.index} component={withTracker(Part01)} exact/>
                    <Route path={locations.part01.sectionA.page1} component={withTracker(Part01_SectionA_01)}/>
                    <Route path={locations.part01.sectionA.page2} component={withTracker(Part01_SectionA_02)}/>
                    <Route path={locations.part01.sectionA.page3} component={withTracker(Part01_SectionA_03)}/>
                    <Route path={locations.part01.sectionB.page1} component={withTracker(Part01_SectionB_01)}/>
                    <Route path={locations.part01.sectionB.page2} component={withTracker(Part01_SectionB_02)}/>
                    <Route path={locations.part01.sectionB.page3} component={withTracker(Part01_SectionB_03)}/>
                    <Route path={locations.part01.sectionC.page1} component={withTracker(Part01_SectionC_01)}/>
                    <Route path={locations.part01.sectionC.page2} component={withTracker(Part01_SectionC_02)}/>
                    <Route path={locations.finalReport} component={withTracker(FinalReport)}/>
                    <Route path={locations.part02.index} component={withTracker(Part02)} exact/>
                    <Route path={locations.part02.sectionA.page1} component={withTracker(Part02_SectionA_01)}/>
                    <Route path={locations.part02.sectionB.page1} component={withTracker(Part02_SectionB_01)}/>
                    <Route path={locations.part02.sectionB.page2} component={withTracker(Part02_SectionB_02)}/>
                    <Route path={locations.part02.sectionC.page1} component={withTracker(Part02_SectionC_01)}/>
                    <Route path={locations.part02.sectionD.page1} component={withTracker(Part02_SectionD_01)}/>

                    <Route path={locations.selfAssessment.overview} component={withTracker(Overview)}/>
                    <Route path={locations.selfAssessment.selfAssessment.path} component={withTracker(SelfAssessment)}/>
                    <Route path={locations.selfAssessment.individualReport.path} component={withTracker(SelfAssessmentReport)}/>
                    <Route path={locations.selfAssessment.report.path} component={withTracker(SelfAssessmentReport)}/>
                    <Route path={locations.selfAssessment.reportChart.path} component={withTracker(SelfAssessmentReportChart)}/>
                    <Route path={locations.selfAssessment.page1.path} component={withTracker(AssessmentPage01)}/>
                    <Route path={locations.selfAssessment.page2} component={withTracker(AssessmentPage02)}/>
                    <Route path={locations.selfAssessment.page3} component={withTracker(AssessmentPage03)}/>


                    <Route path={locations.home} component={withTracker(Home)}/>


                </Switch>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;

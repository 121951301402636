import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import {setPageTitle} from "../../../../Helpers/setPageTitle";
import image01 from '../../../../Images/01-01.png'
import image02 from '../../../../Images/01-02.png'
import Reflection from '../../../../Components/Reflections/Reflection';
import Banner from "../../../../Components/Common/Banner";
import locations from "../../../../Helpers/locations";

function Part01_SectionA_Page01() {
    useLayoutEffect(() => {
        setPageTitle("Section A - 3rd Grade ELA Trend Data");
    }, []);
    return (

        <>
            <Banner page="dnt"/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part I: Instruction and Learning &gt; <strong>Section
                    A.</strong>
                </div>

                <div className="content-block content-description text-center">
                    <h2>A. 3rd Grade ELA Performance</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">
                        Are we preparing all of our 3rd graders to shift from learning to read to reading to learn?
                    </h3>
                    <p>
                    <a onClick={() => window.open(image01, "_blank")}><img src={image01} alt='Leon District Student Performance by Subgroup'/></a>
                    </p>
                    <p>
                        This chart depicts FSA ELA scores over a five year span. It shows the percent of
                        student who are proficient by grade level each year for grades 3-5, meaning those
                        students who score a Level 3 or above. It also allows you to view the changes in
                        cohort performance over time.
                    </p>

                    <div className="question-container lightergold">
                        <h4>Access Your TREND DATA</h4>
                        <p>From the <a href="https://edstats.fldoe.org" target="_blank" rel="noopener noreferrer">EdStats
                            homepage</a>, click on the PK-12 Public Schools icon.</p>
                        <ul>
                            <li>Click on a specific assessment (ELA, etc.).</li>
                            <li>Select the School Level tab.</li>
                            <li>Select your District on the left side of the page.</li>
                            <li>Click View Report on the right side of the page.</li>
                            <li>Select your school on the left side of the page.</li>
                        </ul>
                    </div>
                    <h3 className="text-center">
                        How well are we catching up 4th & 5th graders who are below proficiency in ELA?
                    </h3>
                    <p>
                    <a onClick={() => window.open(image02, "_blank")}><img src={image02} alt='Bond Elementary School Student Performance by Subgroup'/></a>
                    </p>
                    <p>
                        This table depicts FSA data over a five year span. For grades 3-5, the chart depicts the
                        percentage of students in who scored a Level 3 and above on the ELA portion of the FSA at each
                        grade level. It also shows the percentage of students who scored a 4 or above for grades 3-5.
                        Looking at these percentages over a five year span allows school leaders to identify trends in
                        their data.
                    </p>
                    <br/>
                    <h4 className="reflectionHeader">Reflection questions</h4>
                    <Reflection reflectionId={"A-01"} />
                </div>
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.part01.sectionA.page2} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part01.index} className="button back-button">&#9664; BACK</Link>
            </div>

        </>
    );
}

export default Part01_SectionA_Page01;

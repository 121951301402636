import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Banner from "../../../Components/Common/Banner";
import locations from "../../../Helpers/locations";
import {setPageTitle} from "../../../Helpers/setPageTitle";

function Part02() {
    useLayoutEffect(() => {
        setPageTitle("Part II: Context for Learning");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">

                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; <strong>Part II: Context for
                    Learning</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>Part II. Context for Learning</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">Have we allocated our resources and created a culture to ensure we maximize learning for all students?</h3>
                    <div className={"grid-x"}>
                        <div className={"cell small-12 medium-6 large-6"}>
                            <Link to={locations.part02.sectionA.page1} className="button wide-action-button question-button">A.
                                How are
                                highly effective and experienced teachers distributed across classrooms in our school and
                                students of different subgroups?</Link>
                        </div>
                        <div className={"cell small-12 medium-6 large-6"}>
                            <Link to={locations.part02.sectionB.page1} className="button wide-action-button question-button">B.
                                Have we
                                created a culture and climate in our school that is conducive to all students learning and
                                succeeding academically?</Link>
                        </div>
                    </div>
                    <div className={"grid-x"}>
                        <div className={"cell small-12 medium-6 large-6"}>
                            <Link to={locations.part02.sectionC.page1} className="button wide-action-button question-button">C.
                                Do all
                                students have access to high-quality curriculum?<br/><br/></Link>
                        </div>
                        <div className={"cell small-12 medium-6 large-6"}>
                            <Link to={locations.part02.sectionD.page1} className="button wide-action-button question-button">D.
                                How do
                                professional learning investments support a deliberate strategy to close student achievement
                                gaps?</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.part02.sectionA.page1} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.intro.page2} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part02;

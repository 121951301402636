import {objectHasProperty} from "../../Helpers/objectUtilities";
import {
    assessmentIndicators,
    ReflectionId,
    ReportType,
    ReportTypeList
} from "../../Views/SelfAssessment/selfAssessmentConstants";

export const participantManager = {
    // LSK = local storage key
    LSK_PARTICIPANT_IDS: "lsk_participant_ids",
    LSK_CUR_PARTICIPANT_ID: "lsk_cur_participant_id",
    LSK_PARTICIPANT_DATA_PREFIX: "participant-",
    NO_DATA: "pm_no_data",
    ERROR: "pm_error",

    getParticipantIds: (path) => {
        let ids_str = localStorage.getItem(participantManager.LSK_PARTICIPANT_IDS + path);

        if (ids_str == null) {
            return [];
        }

        return ids_str.split(",");
    },

    addParticipantId: (path, participantId) => {
        let ids = participantManager.getParticipantIds(path);
        if (!ids.includes(participantId))
            ids.push(participantId);
        localStorage.setItem(participantManager.LSK_PARTICIPANT_IDS + path, ids.toString());
    },

    removeCategoryData: (path, categoryQuestionIds, pId = "") => {
        let ids = pId === ""
            ? participantManager.getParticipantIds(path)
            : [pId];

        for (let i = 0; i < ids.length; i++) {
            let key = participantManager.LSK_PARTICIPANT_DATA_PREFIX + ids[i] + path;
            localStorage.removeItem(key);

            for (let j = 0; j < categoryQuestionIds.length; j++)
                assessmentStorage.removeResponse(path, categoryQuestionIds[j], ids[i]);

            for(let j = 0; j < ReportTypeList.length; j++)
                assessmentStorage.removeResponse(path, assessmentStorage.getReflectionKey(ReportTypeList[j].path), ids[i]);
        }

        if(pId === "")
            localStorage.removeItem(participantManager.LSK_PARTICIPANT_IDS + path)
        else
        {
            const otherIds = participantManager.getParticipantIds(path).filter(p => p !== pId);

            if(otherIds.length === 0)
                localStorage.removeItem(participantManager.LSK_PARTICIPANT_IDS + path);
            else
                localStorage.setItem(participantManager.LSK_PARTICIPANT_IDS + path, otherIds.toString());
        }
    },

    getCurParticipantId: () => {
        let curId = localStorage.getItem(participantManager.LSK_CUR_PARTICIPANT_ID);
        if (curId == null)
            return participantManager.NO_DATA;
        return curId;
    },

    setParticipantData: (path, participantId, dataObj) => {
        let data_str = JSON.stringify(dataObj);
        let key = participantManager.LSK_PARTICIPANT_DATA_PREFIX + participantId + path;
        localStorage.setItem(key, data_str);
    },

    getParticipantData: (path, participantId) => {
        let key = participantManager.LSK_PARTICIPANT_DATA_PREFIX + participantId + path;
        let data_str = localStorage.getItem(key);
        if (data_str == null)
            return participantManager.NO_DATA;

        try {
            return JSON.parse(data_str);
        } catch (err) {
            return participantManager.ERROR;
        }
    },

    getCurParticipantData: (path) => {
        let id = participantManager.getCurParticipantId();
        if (id === participantManager.NO_DATA)
            return participantManager.NO_DATA;

        return participantManager.getParticipantData(path, id);
    },

    setCurParticipantId: (participantId) => {
        localStorage.setItem(participantManager.LSK_CUR_PARTICIPANT_ID, participantId);
    },

    resetCurParticipantId: () => {
        localStorage.setItem(participantManager.LSK_CUR_PARTICIPANT_ID, participantManager.NO_DATA);
    },

    getFilteredParticipantIds: (path, atField, byValue) => {
        let allIds = participantManager.getParticipantIds(path);

        let filteredIds = [];

        for (let i = 0; i < allIds.length; i++) {
            let id = allIds[i];
            let dataObj = participantManager.getParticipantData(path, id);
            if (dataObj !== participantManager.NO_DATA &&
                dataObj !== participantManager.ERROR) {
                if (objectHasProperty(dataObj, atField)) {
                    if (dataObj[atField] === byValue) {
                        filteredIds.push(id);
                    }
                }
            }
        }
        return filteredIds;
    }
};

export const assessmentStorage = {
    getReflectionKey: (category) => {
        return `${ReflectionId}-${category}`;
    },

    getKey: (path, questionId, participantId) => {
        return `${participantId}-${questionId}-${path}`;
    },

    saveResponse: (path, questionId, participantId, value) => {
        localStorage.setItem(assessmentStorage.getKey(path, questionId, participantId), value);
    },

    getResponse: (path, questionId, participantId) => {
        return localStorage.getItem(assessmentStorage.getKey(path, questionId, participantId));
    },

    removeResponse: (path, questionId, participantId) => {
        localStorage.removeItem(assessmentStorage.getKey(path, questionId, participantId));
    },

    getResponsesForParticipants: (path, questionId, participantIds) => {
        let responses = [];
        for (let i = 0; i < participantIds.length; i++) {
            let participantId = participantIds[i];
            let response = assessmentStorage.getResponse(path, questionId, participantId);
            responses.push(response);
        }

        return responses;
    },

    getParticipantResponses: (path, questionIds, participantId) => {
        let responses = [];
        for (let i = 0; i < questionIds.length; i++) {
            let questionId = questionIds[i];
            let response = assessmentStorage.getResponse(path, questionId, participantId);
            responses.push(response);
        }

        return responses;
    },

    switchParticipantTemporaryResponses: (path, questionIds, participantId) => {
        let tempParticipantId = participantManager.NO_DATA;
        for (let i = 0; i < questionIds.length; i++) {
            let val = assessmentStorage.getResponse(path, questionIds[i], tempParticipantId);

            if (val !== null) {
                assessmentStorage.saveResponse(path, questionIds[i], participantId, val);
                assessmentStorage.removeResponse(path, questionIds[i], tempParticipantId);
            }
        }
    },

    validateAllResponsesSelected: (path, questionIds, participantId) => {
        let allSelected = true;

        for (let i = 0; i < questionIds.length && allSelected; i++) {
            let response = assessmentStorage.getResponse(path, questionIds[i], participantId)

            if (!response)
                allSelected = false;
        }

        return allSelected;
    }

};

export const reportingManager = {

    calculateResponseAvgValue: (path, questionId, participantIds) => {
        let sum = 0;
        let responses = assessmentStorage.getResponsesForParticipants(path, questionId, participantIds);
        let count = 0;
        for (let i = 0; i < responses.length; i++) {
            if (responses[i] !== null) {
                sum += Number(responses[i]);
                count++;
            }
        }

        if (count === 0) return null;

        return sum / count;
    },

    calculateCategoryAvgValue: (path, catQuestionIds, participantIds) => {
        let sum = 0;
        let count = 0;
        for (let i = 0; i < catQuestionIds.length; i++) {
            let questionId = catQuestionIds[i];
            let responses = assessmentStorage.getResponsesForParticipants(path, questionId, participantIds);

            for (let k = 0; k < responses.length; k++) {
                if(!responses[k])
                    continue;

                sum += Number(responses[k]);
                count++;
            }
        }

        if (count === 0) return null;

        return sum / count;
    },

    calculateCategoryAvgValueByRole: (path, catQuestionIds, roleFieldName, roleValue) => {
        let participantIds = participantManager.getFilteredParticipantIds(path, roleFieldName, roleValue);
        return reportingManager.calculateCategoryAvgValue(path, catQuestionIds, participantIds);
    },

    calculateAllParticipantResponseAvgValue: (path, questionId) => {
        let participantIds = participantManager.getParticipantIds(path);
        return reportingManager.calculateResponseAvgValue(path, questionId, participantIds);
    },

    calculateAllParticipantCategoryAvgValue: (path, catQuestionIds) => {
        let participantIds = participantManager.getParticipantIds(path);
        return reportingManager.calculateCategoryAvgValue(path, catQuestionIds, participantIds);
    },

    getCategoryQuestionIds: (category) => {
        return assessmentIndicators.filter(f => f.Category === category).map(m => m.id);
    },

    getCategoryAverages: (path, sections) => {
        let averages = [];

        if(sections.length === 0) return averages;

        for (const [index, section] of sections.entries()) {
            averages.push({
                section: {...section},
                average: reportingManager.calculateAllParticipantCategoryAvgValue(path, reportingManager.getCategoryQuestionIds(section.title))
            })
        }

        averages.sort((a, b) => (a.average > b.average) ? 1 : -1);

        return averages;
    },

    getAllParticipantComments: (category, path) => {
        let participantIds = participantManager.getParticipantIds(path);
        let comments = [];
        for (let i = 0; i < participantIds.length; i++) {
            let comment = assessmentStorage.getResponse(path, assessmentStorage.getReflectionKey(category), participantIds[i]);
            if (comment && comment.trim())
                comments.push(comment);
        }

        return comments;

    },

    getScoreAnalysis: (average, defaultColor = "") => {
        if(average === null || average === undefined)
            return analysisModel(defaultColor, "", "");

        if(average < 1)
            return analysisModel("score-gray", "Beginning", "This means your school is still in the early stages of this work.");

        if(average < 2)
            return analysisModel("score-orange", "Planning", "This means your school is developing or has developed a plan of action. However, you have yet to implement your plan.");

        if(average < 2.7)
            return analysisModel("score-yellow", "Progressing", "This means your school is making progress, but still navigating some challenges.");

        if(average <= 3)
            return analysisModel("score-green", "Implementing", "This means your school has a cohesive plan and is making progress, but still has some refinements to make.");
    }
};

export const analysisModel = (colorCss, overallStatus, statusDescription) => ({
    ColorCss: colorCss,
    OverallStatus: overallStatus,
    StatusDescription: statusDescription,
});

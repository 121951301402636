import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Banner from "../../../../Components/Common/Banner";
import LearningQuestionContext from "../../../../Components/LearningQuestionContext";
import locations from "../../../../Helpers/locations";
import {setPageTitle} from "../../../../Helpers/setPageTitle";

function Part02_SectionB_Page02() {
    useLayoutEffect(() => {
        setPageTitle("Section B - Climate and Culture: Engagement and Support");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">

                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part II: Context for Learning &gt;
                    <strong>Section
                        B.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>B. Climate and Culture: Engagement and Support</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">Have we created a culture and climate in our school that is conducive to
                        all students learning and succeeding academically?</h3>

                    <div className="question-container lightaquablue">
                        <h4>Family Engagement and Supports</h4>
                        <p>
                            To what extent does each subgroup have access to targeted social and emotional
                            services, programs, and resources such as counselors, social workers, or other mental
                            health resources?
                        </p>

                        <p><em>Consider usage rates relative to student demographic proportions.</em></p>

                        <p>
                            What do we know about parent engagement using data from our district’s annual survey?
                        </p>
                        <p><em>Consider the percentage by demographic subgroup and home language spoken of the
                            following:</em></p>
                        <ul>
                            <li><em>Parents reporting feeling welcome at their child&apos;s school</em></li>
                            <li><em>Parents reporting being consulted and informed about the academic lives of their
                                children</em>
                            </li>
                            <li><em>Parents reporting feeling engaged in their child&apos;s learning</em></li>
                        </ul>
                        <LearningQuestionContext/>
                    </div>
                </div>
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.part02.sectionC.page1} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part02.sectionB.page1} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part02_SectionB_Page02;

export default {
	// more options - http://codeseven.github.io/toastr/demo.html
	ServerError: {
		"closeButton": true,
		"preventDuplicates": true,
		"timeOut": 0,
		"extendedTimeOut": 0,
	},

	Error: {
		"closeButton": true,
		"preventDuplicates": true,
		"timeOut": 0,
		"extendedTimeOut": 0,
	},

	Success: {
		"closeButton": true,
		"preventDuplicates": true,
		"progressBar": true,
	},

	Info: {
		"closeButton": true,
		"preventDuplicates": true,
		"progressBar": true,
	},

	Warning: {
		"closeButton": true,
		"preventDuplicates": true,
		"progressBar": true,
	},
};

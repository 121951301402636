import { DefaultError } from "./DefaultError";
import { NoNotification } from "./NoNotification";
import { DefaultNotification } from "./DefaultNotification";
import { MultiErrorMessageNotification } from "./MultiErrorMessageNotification";
import { NotificationTypes } from "./NotificationTypes";
import { ToastrAdapter } from "./ToastrAdapter";
import { catchError } from "../../Helpers/requestUtility";

export class NotificationFactory {
	constructor() {
		this._toastrAdapter = new ToastrAdapter();
	}

	createNone() {
		return new NoNotification();
	}

	createSuccess(message) {
		return new DefaultNotification(this._toastrAdapter, NotificationTypes.SUCCESS, message);
	}

	createError(message) {
		return new DefaultNotification(this._toastrAdapter, NotificationTypes.ERROR, message);
	}

	createWarning(message) {
		return new DefaultNotification(this._toastrAdapter, NotificationTypes.WARNING, message);
	}

	createErrorsFromXhrObject(xhrObject) {
		if (xhrObject.status === 403) return new DefaultNotification(this._toastrAdapter, NotificationTypes.ERROR, "Please contact support to verify your access to this section.");

		if (xhrObject.responseText === "") return new NoNotification();

		let errorResponseObject;

		try {
			errorResponseObject = JSON.parse(xhrObject.responseText);
		} catch (e) {
			catchError("The server did not return a valid error object.");
			return new DefaultError(this._toastrAdapter);
		}

		if (!errorResponseObject || !errorResponseObject.errors) return new DefaultError(this._toastrAdapter);

		let notification;
		let totalErrors = errorResponseObject.totalErrors;

		if (totalErrors === 1) notification = new DefaultNotification(this._toastrAdapter, NotificationTypes.ERROR, errorResponseObject.errors[0].message);
		else if (totalErrors > 1) notification = new MultiErrorMessageNotification(this._toastrAdapter, errorResponseObject.errors);
		else notification = new NoNotification();

		return notification;
	}

	createInfo(message) {
		let notification = new DefaultNotification(this._toastrAdapter, NotificationTypes.INFO, message);
		return notification;
	}
}

import React from "react";
import contextLearningQuestions from "../../Data/Part 2 Context for Learning Questions.docx";

function LearningQuestionContext() {
    return (
        <p className={"learning-question-context"}>
            <a href={contextLearningQuestions} target="_blank" rel="noreferrer noopener" download>Part 2: Context for Learning Questions (.docx format)</a>
        </p>
    );
}

export default LearningQuestionContext;

import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Reflection from "../../../../Components/Reflections/Reflection";
import Banner from "../../../../Components/Common/Banner";
import locations from "../../../../Helpers/locations";
import {setPageTitle} from "../../../../Helpers/setPageTitle";

function Part01_SectionB_Page03() {
    useLayoutEffect(() => {
        setPageTitle("Section B - 4th & 5th Grade - Math State & District Comparison");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part I: Instruction and
                    Learning &gt; <strong>Section
                    B.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>B. Math Performance</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="reflectionHeader">Reflection questions</h3>

                    <Reflection reflectionId={"B-03-01"} />

                    <Reflection reflectionId={"B-03-02"} />

                    <Reflection reflectionId={"B-03-03"} />
                </div>
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.part01.sectionC.page1} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part01.sectionB.page2} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part01_SectionB_Page03;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {render} from 'react-dom';
import GoogleAnalytics from "react-ga";
import App from './App';
import {GA_TRACKING_CODE} from "./Components/Common/Constants/config";
import * as serviceWorker from "./serviceWorker";
import "./robots.txt";
import "./logo192.png";
import "./logo512.png";
import "foundation-sites/dist/css/foundation.min.css";
import "./scss/site.scss";

GoogleAnalytics.initialize(GA_TRACKING_CODE);


render(<App />, document.getElementById("root"));
serviceWorker.unregister();

import React from "react";
import PropTypes from "prop-types";
import {reportingManager} from "../SelfAssessment/AssessmentLogic";

function ChartReportTableRow({role, sections, path, columnsCss}) {

    const getCategoryRoleAverage = (section, index) => {
        let roleAvg = reportingManager.calculateCategoryAvgValueByRole(path, reportingManager.getCategoryQuestionIds(section.title), "role", role);

        let averageValue = "N/A";
        if (roleAvg !== null) averageValue = roleAvg.toFixed(1);

        return (
            <div key={`column_${role}_${index}`} className={`${columnsCss} ${reportingManager.getScoreAnalysis(roleAvg).ColorCss}`}>
                <p>
                    {averageValue}
                </p>
            </div>
        );
    };

    return (
        <>
            <div className="grid-x score-table-row">
                <div className={columnsCss}>
                    <p>
                        {role}
                    </p>
                </div>
                {
                    sections.map((section, index) => {
                        return getCategoryRoleAverage(section, index);
                    })
                }
            </div>
        </>
    );

}

ChartReportTableRow.propTypes = {
    role: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    columnsCss: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ChartReportTableRow;
import React, {useState, useLayoutEffect, useRef} from "react";
import {STICKY_HEADER as style} from "../../Helpers/styleconstants";
import NavigationMenu from "./NavigationMenu";

function StickyHeader() {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const wrapperRef = useRef(null);

    const handleMenuClick = (e) => {
        setIsMenuVisible(!isMenuVisible);
    };

    const handleLogoClick = (e) => {
        e.preventDefault();
        window.location.href = "/";
    };

    useLayoutEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target) && event.target.className !== "icon-navicon") {
                setIsMenuVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={style.stuckHeader}>
            <div className={style.menuCell} onClick={handleMenuClick}>
                <span className={style.navigationIcon}></span>
            </div>
            <nav role="navigation" ref={wrapperRef} style={{"height": `${window.innerHeight - 75}px`}}>
                {
                    isMenuVisible &&
                    <NavigationMenu handleMenuClick={handleMenuClick}/>
                }
            </nav>
            <div className={style.logoCell} onClick={handleLogoClick}>
                <div></div>
            </div>
            <div className={style.titleCell}><h1>Data Impact</h1></div>
        </div>
    );
}

export default StickyHeader;
import React from "react";
import PropTypes from "prop-types";
import {assessmentButton} from "../../Views/SelfAssessment/selfAssessmentConstants";

function SelfAssessmentButton({index, buttonClassName, handleClick}) {

    const text = assessmentButton[index].text;
    const value = assessmentButton[index].value;

    return (
        <>
            <div className={buttonClassName}>
                <button onClick={(event) => handleClick(event, index, value)}>
                    <div className={"grid-y response-text-container"}>
                        <p className={"cell big-number shrink"}>{value}</p>
                        <p className={"cell"}>{text}</p>
                    </div>
                </button>
            </div>
        </>
    )
}

SelfAssessmentButton.propTypes = {
    index: PropTypes.number.isRequired,
    buttonClassName: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired
};

export default SelfAssessmentButton;

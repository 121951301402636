import React, {useLayoutEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Banner from "../../Components/Common/Banner";
import {reportingManager} from "../../Components/SelfAssessment/AssessmentLogic";
import RoleGrid from "../../Components/SelfAssessmentReport/RoleGrid";
import locations from "../../Helpers/locations";
import {setPageTitle} from "../../Helpers/setPageTitle";
import Resources from "../../Components/SelfAssessmentReport/Resources";
import {ReportType, ReportTypeList} from "./selfAssessmentConstants";

function ReportChart() {
    const sections = ReportTypeList.filter(f => f.path !== ReportType.Full.path);
    const {...params} = useParams();
    const [reportType, setReportType] = useState(null);

    useLayoutEffect(() => {
        setPageTitle("Self Assessment Summary Report");

        const getReportTypeParam = () => {
            const report = ReportType[params.reportType] ? ReportType[params.reportType] : ReportType.Full;
            setReportType(report);
        };
        getReportTypeParam();
    }, []);

    if(!reportType)
        return null;

   return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Self Assessment &gt; <strong>Participant</strong>
                </div>

                <RoleGrid sections={sections} path={reportType.path} />

                <Resources categoryAveragesForChart={reportingManager.getCategoryAverages(reportType.path, sections)} />
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.selfAssessment.selfAssessment.getPath(reportType.path)} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.selfAssessment.report.getPath(reportType.path)} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default ReportChart;

import PropTypes from "prop-types";
import React, {useLayoutEffect, useState} from "react";
import SelfAssessmentButton from "./SelfAssessmentButton";
import {ASSESSMENT as style} from "../../Helpers/styleconstants";
import {participantManager, assessmentStorage} from "./AssessmentLogic";

function SelfAssessmentButtonContainer({path, questionId}) {
    const [buttonIndex, setButtonIndex] = useState(-1);

    function handleClick(e, index, qId, value) {
        e.preventDefault();

        let curParticipantId = participantManager.getCurParticipantId();

        assessmentStorage.saveResponse(path, qId, curParticipantId, value);
        setButtonIndex(index);
    }

    const createButtonRow = () => {
        const buttons = [];
        for (let i = 0; i <= 3; ++i) {
            let buttonClassName = style.assessmentButton;
            if (buttonIndex === i) buttonClassName += " selected";
            buttons.push(<SelfAssessmentButton
                key={i}
                index={i}
                handleClick={(event, index, value) => handleClick(event, index, questionId, value)}
                buttonClassName={buttonClassName}
            />)
        }

        return buttons;
    };

    useLayoutEffect(() => {

        let curParticipantId = participantManager.getCurParticipantId();
        const response = assessmentStorage.getResponse(path, questionId, curParticipantId);
        if(response !== null && !isNaN(response))
            setButtonIndex(Number(response));
    }, []);

    return (
        <>
            {createButtonRow()}
        </>
    )
}

SelfAssessmentButtonContainer.propTypes = {
    path: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired
};

export default SelfAssessmentButtonContainer;

import {useEffect, useState} from "react";
import {objectHasProperty} from "./objectUtilities";

export function useFormInput(initialValue) {
    const [value, setValue] = useState(initialValue);

    function handleChange(e) {
        const value = e && e.target ? e.target.value : e;
        console.log(value);
        setValue(value);
    }

    return {
        onChange: handleChange,
        setValue,
        value
    };
}

export function useMemento(initialValue) {
    const [value, setValue] = useState(initialValue);
    const [mementoValue, setMementoValue] = useState([]);

    function setStateValue(valueToSet){
        setValue(valueToSet);
        setMementoValue([...mementoValue, valueToSet]);
    }

    function handleChange(e) {
        const value = e && e.target ? e.target.value : e;
        setMementoValue([...mementoValue, value]);
        setValue(value);
    }

    function rollback() {
        if (!mementoValue || mementoValue.length <= 1)
            return;

        const previousValue = mementoValue;
        previousValue.pop();
        setMementoValue(previousValue);
        setValue(mementoValue[mementoValue.length - 1]);
    }

	return {
		onChange: handleChange,
		rollback,
        setStateValue,
		value
	};
}

export function useResizeWindow() {
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    function resizeWindow(){
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    }

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    });

    return {
        windowHeight,
        windowWidth
    }
}

export function convertCamelCaseToSpaceDelimited(string = "") {
    const regex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    return string.replace(regex, '$1$4 $2$3$5');
}

export function createListFromObject(object) {
    let result = [];
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            result.push(createListItem(object[property], convertCamelCaseToSpaceDelimited(object[property]), property));
        }
    }
    return result;
}

export function createListItem(value, text, id) {
    return {
        id: id ? id : value,
        label: text ? text : value,
        text: text ? text : value,
        value: value
    };
}

export function isInSelectList(list, string) {
    return list && !!list.filter(item => item.value === string || item.text === string).length;
}

export function getSelectedValuesAsInt(list) {
    if (!list)
        return list;

    return list.map(item => parseInt(item.value));
}

function S4() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
}

export function createGuid() {
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}

export function convertLineBreaksToBreakTags(rawString) {
    if (!rawString) return "";

    return rawString.replace(/(?:\r\n|\r|\n)/g, "<br>");
}
import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Banner from "../../../../Components/Common/Banner";
import LearningQuestionContext from "../../../../Components/LearningQuestionContext";
import locations from "../../../../Helpers/locations";
import {setPageTitle} from "../../../../Helpers/setPageTitle";

function Part02_SectionB_Page01() {
    useLayoutEffect(() => {
        setPageTitle("Section B - Climate and Culture: Attendance and Discipline");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part II: Context for Learning &gt;
                    <strong>Section
                        B.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>B. Climate and Culture: Attendance and Discipline</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">Have we created a culture and climate in our school that is conducive to
                        all students learning and succeeding academically?</h3>

                    <div className="question-container lightaquablue">
                        <h4>Attendance</h4>
                        <p>
                            How different are average daily attendance rates and chronic absenteeism rates across
                            student subgroups?
                        </p>
                        <p>
                            <em>Consider which subgroups have the highest rates and/or biggest gaps. Also consider
                                (dis)proportionality in average daily attendance and chronic absenteeism by student subgroup.</em>
                        </p>
                    </div>
                    <div className="question-container lightaquablue">
                        <h4>Discipline</h4>
                        <p>
                            How consistent and fair are our disciplinary processes for students in various subgroups?
                        </p>
                        <p>
                            <em>Consider (dis)proportionality in disciplinary actions by student subgroup, relative
                            frequency of disciplinary actions by type of infraction for each subgroup, etc.</em>
                        </p>
                    </div>
                    <div className="question-container lightaquablue">
                        <LearningQuestionContext/>
                    </div>
                </div>
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.part02.sectionB.page2} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part02.sectionA.page1} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part02_SectionB_Page01;

import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Banner from "../../../Components/Common/Banner"
import locations from "../../../Helpers/locations";
import {setPageTitle} from "../../../Helpers/setPageTitle";

function Intro02() {
    useLayoutEffect(() => {
        setPageTitle("Explore State Data");
    }, []);
    return (

        <>
            <Banner page="dnt"/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; <strong>Explore State Data</strong>
                </div>

                <div className="content-block content-description text-center">
                    <h2>Explore State Data</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">Do our instructional practices ensure that <span className="underline">all students</span> in
                        our school receive high-quality opportunities to learn?</h3>
                    <Link to={locations.part01.index} className="button wide-action-button question-button"><span className="bold">Part I:<br/>Instruction and Learning</span><br/>How
                        are our student subgroups performing relative to all other students?</Link>
                    <Link to={locations.part02.index} className="button wide-action-button question-button"><span
                        className="bold">Part II:<br/>Context for Learning</span><br/>Have we allocated our resources
                        and created a culture to ensure we maximize learning for all students?</Link>
                </div>
            </div>

        </>
    );
}

export default Intro02;

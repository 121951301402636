import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import {setPageTitle} from "../../../../Helpers/setPageTitle";
import image01 from '../../../../Images/04-01.png'
import image02 from '../../../../Images/04-02.png'
import Reflection from '../../../../Components/Reflections/Reflection';
import Banner from "../../../../Components/Common/Banner";
import locations from "../../../../Helpers/locations";

function Part01_SectionB_Page01() {
    useLayoutEffect(() => {
        setPageTitle("Section B - 4th & 5th Grade Math Performance/Trend Data");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">

                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part I: Instruction and
                    Learning &gt; <strong>Section
                    B.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>B. 4th & 5th Grade Math Performance</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">How well are we catching up 4th & 5th graders who are below proficiency
                        in Math?</h3>
                    <div className="question-container lightergold">
                        <h4>Trend Data</h4>
                        <p>
                            EdStats allows you to see overall proficiency for your school by grade level for the past 5
                            years. This allows you to view the performance of specific cohorts over a three year window.
                        </p>
                    </div>
                    <p>
                        <a onClick={() => window.open(image01, "_blank")}><img src={image01}
                                                                               alt='Bond Elementary School Student Performance by Subgroup'/></a>
                    </p>
                    <div className="question-container lightergold">
                        <h4>Access 4th and 5th Grade Performance Data</h4>
                        <p>From the FLDOE.org homepage...</p>
                        <ul>
                            <li>Click on Accountability.</li>
                            <li>Click on School Grades.</li>
                            <li>Under Accountability Reports, select Mathematics.</li>
                        </ul>
                    </div>

                    <h4 className="reflectionHeader">Reflection question</h4>
                    <Reflection reflectionId={"A-04-01"} />

                    <h3 className="text-center">How well are we catching up 4th & 5th graders who are below proficiency
                        in Math?</h3>
                    <div className="question-container lightergold">
                        <h4>Trend Data</h4>
                        <p>
                            FLDOE.org allows you to view Learning Gains for the Lowest 25%, the Highest 25%, and the
                            student population as a whole.
                        </p>
                    </div>

                    <p>
                        <a onClick={() => window.open(image02, "_blank")}><img src={image02} alt='2018-19 Mathematics Learning Gains'/></a>
                    </p>
                    <p>
                        This excel spreadsheet depicts the math learning gains of the lowest 25% by district and by
                        school. It allows you to compare the math learning gains of your school by grade level.
                    </p>


                    <div className="question-container lightergold">
                        <h4>Access 4th and 5th Grade Performance Data</h4>
                        <p>From the FLDOE.org homepage...</p>
                        <ul>
                            <li>Click on Accountability.</li>
                            <li>Click on School Grades.</li>
                            <li>Under Accountability Reports, select Mathematics.</li>
                        </ul>
                    </div>

                    <h4 className="reflectionHeader">Reflection question</h4>
                    <Reflection reflectionId={"A-04-02"} />

                </div>
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.part01.sectionB.page2} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part01.sectionA.page3} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part01_SectionB_Page01;

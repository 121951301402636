import React, {useLayoutEffect, useState} from "react";
import {Link} from "react-router-dom";
import Banner from "../Components/Common/Banner";
import planningGuide from "../Data/Section 3 Planning Guide.docx"
import locations from "../Helpers/locations";
import {setPageTitle} from "../Helpers/setPageTitle";

const Home = () => {
    const [noLocalStorageCss, setNoLocalStorageCss] = useState("");

    const handleClearAllData = () => {
        if (noLocalStorageCss === "" && window.confirm(`Are you sure you want to clear all the data used in this site?`)) {
            localStorage.clear();
            setNoLocalStorageCss("disabled");
        }
    };

    useLayoutEffect(() => {
        setPageTitle("Data Impact Project");

        let buttonCss = "";
        if (localStorage.length === 0)
            buttonCss = "disabled";

        setNoLocalStorageCss(buttonCss);
    }, []);
    return (
        <>
            <Banner page="home"/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    <strong>Overview</strong>
                </div>

                <div className="content-block content-description">

                    <p>In partnership with the Bill and Melinda Gates Foundation, the Florida Department of Education
                        (FDOE) has begun a pilot initiative called the Data Impact Project (DIP) to help build the
                        capacity of school leaders. Specifically, this project aims to help leaders effectively leverage
                        their data to positively impact student learning. The data tools and guidance found on this site
                        are intended to support the data literacy and instructional decision-making practices of school
                        leaders.
                    </p>

                    <h2>Vision</h2>
                    <p>Our vision for this work involves empowering school leaders to make more strategic instructional
                        decisions based on all available, relevant data. School leadership teams that engage with the
                        FDOE Data Impact Project will:
                    </p>
                    <ul>
                        <li>Analyze and leverage various state data sources to make more data-informed decisions and to
                            assess and improve their current data systems;
                        </li>
                        <li>Increase their data literacy and ability to identify and target equity gaps within their
                            schools; and
                        </li>
                        <li>Strategically apply relevant data to inform targeting educator support, improving
                            instruction, and increasing educational outcomes for ALL students.
                        </li>
                    </ul>
                    <div>
                        <Link to={locations.selfAssessment.overview}
                              className="button light-action-button wide-action-button">Self
                            Assessment</Link>
                    </div>
                    <div>
                        <Link to={locations.intro.page1} className="button light-action-button wide-action-button">Data
                            Navigation
                            Tool</Link>
                    </div>
                    <div>
                        <a href={planningGuide} target="_blank" rel="noreferrer noopener" download
                           className="button light-action-button wide-action-button">Action Planning Guide</a>
                    </div>
                    {
                        noLocalStorageCss === "" &&
                        <p className={"extra-vertical-margin align-center"}>
                            <a onClick={handleClearAllData}>Clear All Data</a>
                        </p>
                    }
                </div>
            </div>
        </>
    );
};

export default Home;

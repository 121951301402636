import React from "react";

function SelfAssessmentDirections() {
    return (
        <div className={"content-block content-questionnaire"}>
            <p>For each indicator below, select the rating that best fits your current progress on the indicator.
                If your rating is between two scores, default to choosing the lower score.</p>
        </div>
    )
}

export default SelfAssessmentDirections;

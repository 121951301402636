import React from "react";
import PropTypes from "prop-types";
import {convertLineBreaksToBreakTags} from "../../Helpers/utilities";
import ScoreExplanation from "../../Views/SelfAssessment/ScoreExplanation";
import {assessmentStorage, participantManager, reportingManager} from "../SelfAssessment/AssessmentLogic";
import {assessmentIndicators, ReportTypeList} from "../../Views/SelfAssessment/selfAssessmentConstants";
import ReportSectionResponse from "./ReportSectionResponse";

function ReportSection({category, path, participantId}) {

    const isParticipantReport = () => {
        return participantId !== null;
    };

    const getParticipantName = () => {
        if (!participantId)
            return "";

        const participant = participantManager.getParticipantData(path, participantId);

        return `${participant.fName} ${participant.lName}`;
    };

    const getCategoryAvgValue = () => {
        const indicatorIds = assessmentIndicators.filter(f => f.Category === category).map(m => m.id);
        return reportingManager.calculateAllParticipantCategoryAvgValue(path, indicatorIds);
    };
    const categoryAvgValue = getCategoryAvgValue();

    const getCategoryAvgValueForOutput = () => {
        return categoryAvgValue ? categoryAvgValue.toFixed(1) : "N/A";
    };

    const getResponses = () => {
        let averages = [];

        assessmentIndicators.filter(f => f.Category === category)
            .map((indicator) => {
                const avg = isParticipantReport()
                    ? assessmentStorage.getResponse(path, indicator.id, participantId)
                    : reportingManager.calculateAllParticipantResponseAvgValue(path, indicator.id);
                averages.push({
                    average: isNaN(avg) ? null : Number(avg),
                    indicator: indicator
                });
            });

        averages.sort((a, b) => (a.average > b.average) ? 1 : -1);

        return averages.map((info, index) => {
            return (
                <ReportSectionResponse
                    key={`${category}_${index}`}
                    average={info.average}
                    questionDataIndicator={info.indicator.Indicator}
                />
            );
        });
    };

    const displayComments = () => {
        const reportType = ReportTypeList.find(f => f.title === category);
        let comments = isParticipantReport()
            ? [assessmentStorage.getResponse(path, assessmentStorage.getReflectionKey(reportType.path), participantId)]
            : reportingManager.getAllParticipantComments(reportType.path, path);

        const sectionLabel = isParticipantReport() ? "Additional reflections and considerations" : "Participant Comments";

        return (
            <>
                <p><b><u>{sectionLabel}</u></b></p>
                {
                    comments.length > 0 &&
                    <ul>
                        {
                            comments.map((comment, index) => {
                                return <li key={index} dangerouslySetInnerHTML={{__html: convertLineBreaksToBreakTags(comment)}}></li>
                            })
                        }
                    </ul>
                }
                {
                    comments.length === 0 &&
                    <p>None</p>
                }
            </>
        );
    };

    return (
        <>
            <div className="content-block content-description text-center">
                <h2>{category}</h2>
            </div>
            <div className="content-block content-questionnaire">
                {
                    !isParticipantReport() &&
                    <>
                        <p>Your average score for this category is <strong>{getCategoryAvgValueForOutput()}</strong>.
                        </p>
                        {
                            getCategoryAvgValueForOutput() !== "N/A" &&
                            <p>
                                {`Based on this score, your school is in the ${reportingManager.getScoreAnalysis(categoryAvgValue).OverallStatus.toLowerCase()} 
                                phase of ${category}.  ${reportingManager.getScoreAnalysis(categoryAvgValue).StatusDescription} You can find more information 
                                about your score in the sections below.`}
                            </p>
                        }

                        <ScoreExplanation/>

                        <p>Below you will see averages indicator scores for all participants in this assessment.</p>
                    </>
                }
                {
                    isParticipantReport() &&
                    <p>Below is the indicator scores for <strong>{getParticipantName()}</strong>.</p>
                }

                {getResponses()}
                <div className='comments'>
                    {displayComments()}
                </div>
            </div>
        </>
    );
}

ReportSection.propTypes = {
    category: PropTypes.string,
    path: PropTypes.string,
    participantId: PropTypes.string,
};

export default ReportSection;

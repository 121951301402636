import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import {setPageTitle} from "../../../../Helpers/setPageTitle";
import image01 from '../../../../Images/02-01.png';
import image02 from '../../../../Images/02-02.png';
import Reflection from '../../../../Components/Reflections/Reflection';
import Banner from "../../../../Components/Common/Banner";
import locations from "../../../../Helpers/locations";

function Part01_SectionA_Page02() {
    useLayoutEffect(() => {
        setPageTitle("Section A - Schoolwide ELA Trend Data");
    }, []);

    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part I: Instruction and
                    Learning &gt; <strong>Section
                    A.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>A. Schoolwide ELA Performance</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">How has our schoolwide ELA performance changed over time? </h3>
                    <p>
                        <a onClick={() => window.open(image01, "_blank")}><img src={image01} alt='Hillsborough County Student Performance by Race/Ethnicity'/></a>
                    </p>
                    <p>
                        This chart depicts FSA data over a five year span. For grades 3-5, the chart depicts
                        the percentage of students in who scored a Level 3 and above on the ELA portion of the
                        FSA at each grade level. It also shows the percentage of students who scored a 4 or
                        above for grades 3-5. Looking at these percentages over a five year span allows school
                        leaders to identify trends in their data.
                    </p>


                    <div className="question-container lightergold">
                        <h4>Access Your TREND DATA</h4>
                        <p>From the <a href="https://edstats.fldoe.org" target="_blank" rel="noreferrer noopener">EdStats
                            homepage</a>, click on the PK-12 Public Schools icon.</p>
                        <ul>
                            <li>Click on ELA (under Assessments).</li>
                            <li>Select the School Level tab.</li>
                            <li>Select your District on the left side of the page.</li>
                            <li>Click View Report on the right side of the page.</li>
                            <li>Select your school on the left side of the page.</li>
                            <li>Scroll down to find the Student Performance by Race/Ethnicity chart</li>
                        </ul>
                    </div>


                    <h3 className="text-center">How are we performing relative to the rest of our district and the
                        state?</h3>
                    <div className="question-container lightergold">
                        <h4>ACHIEVEMENT DATA</h4>
                        <p>
                            <a href="https://edudata.fldoe.org/" target="_blank" rel="noopener noreferrer">EduData</a> allows you to view
                            achievement data for subgroups (including SWDs, ELLs,
                            and Economically Disadvantaged students). It also shows comparative data for the district
                            and state.
                        </p>
                    </div>
                    <p>
                        <a onClick={() => window.open(image02, "_blank")}><img src={image02} alt='2018-19 English Language Arts Assessment Results by Achievement Level'/></a>
                    </p>
                    <p>
                        This chart compares the school’s FSA ELA score averages for English Language
                        Learners to the district and state averages. For example, it shows that roughly 44% of
                        ELLs at Interlachen Elementary School scored a Level 1 on the FSA ELA, while the district
                        and state averages for students who scored a Level 1 were roughly 40% and 33% respectively.
                        This chart can be adjusted to change the year, subject, or specific subgroup.
                    </p>

                    <div className="question-container lightergold">
                        <h4>Subgroup Data</h4>
                        <p>To create this chart(s) for your school:</p>
                        <ul>
                            <li>Go to <a href="https://edudata.fldoe.org/" target="_blank" rel="noreferrer noopener">EduData</a> and access your
                                school’s report card
                            </li>
                            <li>Click View School Performance Components</li>
                            <li>Click Assessments, Academic Achievement, Growth, and Participation</li>
                            <li>Click Assessment Chart Creation</li>
                            <li>Adjust the filters according to the data you want to view</li>
                        </ul>
                    </div>

                    <h4 className="reflectionHeader">Reflection questions</h4>

                    <Reflection reflectionId={"A-02"} />
                </div>
            </div>

            <div className="content-block content-navigation-buttons">
                <Link to={locations.part01.sectionA.page3} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part01.sectionA.page1} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part01_SectionA_Page02;

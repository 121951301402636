import React, {useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import Reflection from "../../../../Components/Reflections/Reflection";
import {setPageTitle} from "../../../../Helpers/setPageTitle";
import image01 from '../../../../Images/07-01.png';
import Banner from "../../../../Components/Common/Banner";
import locations from "../../../../Helpers/locations";

function Part01_SectionC_Page01() {
    useLayoutEffect(() => {
        setPageTitle("Section C - Schoolwide ELA/Math Performance Over Time, Page 1");
    }, []);
    return (
        <>
            <Banner/>
            <div className="grid-x content-container">
                <div className="content-block content-breadcrumbs">
                    Overview &gt; Data Navigation Tool &gt; Explore State Data &gt; Part I: Instruction and
                    Learning &gt; <strong>Section
                    C.</strong>
                </div>
                <div className="content-block content-description text-center">
                    <h2>C. Performance Over Time</h2>
                </div>
                <div className="content-block content-questionnaire">
                    <h3 className="text-center">How has our schoolwide ELA/Math performance changed over time?</h3>
                    <div className='grid-x grid-margin-x'>
                        <div className='cell large-6'>
                            <a onClick={() => window.open(image01, "_blank")}><img src={image01}
                                                                                   alt='School Grades Applicable Points Earned by Grading Forumula Cell'/></a>
                        </div>
                        <div className='cell large-6'>
                            <p>
                                This chart depicts the percent of points earned for ELA, Math and Science that
                                contributed to the school grade over a four-year span. It allows you to compare
                                the achievement, gains, and low gains school grade components by subject area over time.
                                This chart can be accessed within your SIP by click on the Data Analysis Module under
                                Part II: Section A (School Data).
                            </p>
                        </div>
                    </div>


                    <div className="question-container lightergold">
                        <h4>Trend Data</h4>
                        <p><a href="https://www.floridacims.org/" target="_blank"
                              rel="noopener noreferrer">CIMS</a> allows you to see a snapshot
                            of your School Grade components for the past four years.</p>
                        <p>This chart can be accessed within your SIP by click on the Data Analysis Module
                            under Part II: Section A (School Data).
                        </p>
                    </div>

                    <h4 className="reflectionHeader">Reflection questions</h4>
                    <Reflection reflectionId={"A-06-01"} />

                    <Reflection reflectionId={"A-06-02"} />
                </div>
            </div>


            <div className="content-block content-navigation-buttons">
                <Link to={locations.part01.sectionC.page2} className="button next-button">NEXT &#9654;</Link>
                <Link to={locations.part01.sectionB.page3} className="button back-button">&#9664; BACK</Link>
            </div>
        </>
    );
}

export default Part01_SectionC_Page01;
